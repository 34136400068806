import React, { useState, useEffect, useCallback } from "react";
import { getAddons, updateAddon } from "../../apis/users";
import Edit from "./Edit";
import Add from "./Add";
import authentication from "../../utils/authentication";
import { toast } from "react-toastify";

const Addons = () => {
  const [data, setData] = useState([]);
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getAddons();
      if (apiResponse?.data?.data?.length > 0) {
        setData([...apiResponse?.data?.data]);
      } else {
        setData([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, [data]);

  const activateDeactivateHandler = useCallback(async (id, isActive) => {
    try {
      await updateAddon(id, { isActive });

      toast.success(
        isActive
          ? "Addon activated successfully"
          : "Addon deactivated successfully"
      );
      fetch();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="section">
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <Add fetch={fetch} />
          </div>
          <table>
            <thead>
              <th>#</th>
              <th>Description</th>
              <th>Price</th>
              <th>Profiles</th>
              <th>Active/Inactive</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={item._id}>
                  <td>{index + 1}</td>
                  <td>{item.description}</td>
                  <td>{item.price}</td>
                  <td>{item.profiles}</td>
                  <td>{item.isActive ? "Active" : "Inactive"}</td>
                  <td>
                    <div className="flex">
                      {item.isActive && <Edit data={item} fetch={fetch} />}
                      {item?.isActive ? (
                        <button
                          onClick={() =>
                            activateDeactivateHandler(item._id, false)
                          }
                          className="danger"
                        >
                          Deactivate
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            activateDeactivateHandler(item._id, true)
                          }
                          className="success"
                        >
                          Activate
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Addons;
