import './Users.css';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import {
  get,
  getRMAdmins,
  getSubscriptions,
  updateUser,
  updateUserRM,
  upgradeUserPlan,
} from '../../apis/users';

import Add from '../../components/users/Add';
import { CSVLink } from 'react-csv';
import authentication from '../../utils/authentication';
import { toast } from 'react-toastify';

const Users = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [searchUser, setSearchUser] = useState(
    useLocation()
      .search?.split('?')[1]
      ?.split('&')
      ?.filter((el) => el.includes('user'))[0]
      ?.split('=')[1]
      ?.split('%20')
      ?.join(' ') || ''
  );
  const [viewData, setViewData] = useState(null);
  const [requestType, setRequestType] = useState('All');
  const [headers, setHeaders] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const page =
    useLocation()
      .search?.split('?')[1]
      ?.split('&')
      ?.filter((el) => el.includes('page'))[0]
      ?.split('=')[1] || '';
  const search =
    useLocation()
      .search?.split('?')[1]
      ?.split('&')
      ?.filter((el) => el.includes('user'))[0]
      ?.split('=')[1] || '';

  const fetchSubscriptions = useCallback(async () => {
    try {
      const apiResponse = await getSubscriptions();
      if (apiResponse?.data?.length > 0) {
        setSubscriptions([
          ...apiResponse?.data
            ?.filter((el) => el.isFreePlan)
            ?.map((el) => ({
              _id: el._id,
              plan: el.plan,
            })),
          ...apiResponse?.data
            ?.filter((el) => !el.isFreePlan)
            ?.map((el) => ({
              _id: el._id,
              plan: el.plan,
            })),
        ]);
      } else {
        setSubscriptions([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, []);
  const fetchRMAdmins = useCallback(async () => {
    try {
      const apiResponse = await getRMAdmins();
      if (apiResponse?.data?.data?.length > 0) {
        setAdmins([...apiResponse?.data?.data]);
      } else {
        setAdmins([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, []);

  const ageCalculator = (date) => {
    var dob = new Date(date);
    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();

    var age = Math.abs(year - 1970);
    return age;
  };

  const fetchUsers = useCallback(
    async (num) => {
      if (requestType) {
        try {
          const apiResponse = await get(
            num || page,
            searchUser?.trim(),
            requestType
          );

          setData([...apiResponse?.data?.data?.users]);
          setPageInfo({ ...apiResponse?.data?.data?.pageInfo });
          if (apiResponse?.data?.data?.users?.length > 0) {
            let temp = [];
            apiResponse?.data?.data?.users.map((el, index) => {
              if (viewData?._id === el?._id) {
                setViewData(el);
              }
              if (el?._id) {
                let userData = {};
                userData['#'] = index + 1;
                userData['Name'] = el.name;
                userData['Gender'] = el.gender;
                userData['Phone Number'] = el.phoneNumber;
                userData['Email'] = el.email;
                userData['Subscription Status'] =
                  el?.userPlan?.plan &&
                  el?.userPlan?.plan?.substring(0, 1)?.toUpperCase() +
                    el?.userPlan?.plan?.substring(1)?.toLowerCase();
                userData['Expiry Date'] =
                  el?.userPlan?.plan && !el?.userPlan?.isFreePlan
                    ? getExpiryDate(
                        el?.userPlan?.startDate,
                        el?.userPlan?.startTime,
                        el?.userPlan?.pausedDays,
                        el?.userPlan?.duration
                      )
                    : '';
                userData['Active/Inactive'] = el.isActive
                  ? 'Active'
                  : 'Inactive';

                userData['Relationship Manager'] = el?.admin?.name || '';

                if (
                  el?.dateOfBirth?.year &&
                  el?.dateOfBirth?.month &&
                  el?.dateOfBirth?.date
                ) {
                  userData['Age'] = ageCalculator(
                    `${el.dateOfBirth.year}-${el.dateOfBirth.month}-${el.dateOfBirth.date} 00:00:00`
                  );

                  userData[
                    'Date of birth'
                  ] = `${el.dateOfBirth.year}-${el.dateOfBirth.month}-${el.dateOfBirth.date}`;
                } else {
                  userData['Age'] = '';
                  userData['Date Of Birth'] = '';
                }
                userData['Height'] = `${el.heightInCms} cms` || '';
                userData['Annual Income'] =
                  el?.workDetails?.annualEarning || '';
                userData['Marital Status'] = el?.maritalStatus || '';
                userData['Current Residing City'] =
                  el?.currentResidingCity || '';
                userData['Nationality'] = el?.nationality || '';
                userData['Mother Tongue'] = el?.motherTongue || '';
                userData['Religion'] = el?.religion || '';
                userData['Has kids?'] = el?.haveKids || '';
                if (el?.secondarySchool?.name) {
                  let str = el?.secondarySchool?.name;
                  if (el?.secondarySchool?.city && el?.secondarySchool?.year) {
                    str += ` (${el?.secondarySchool?.city} - ${el?.secondarySchool?.year})`;
                  } else if (el?.secondarySchool?.city) {
                    str += ` (${el?.secondarySchool?.city})`;
                  } else if (el?.secondarySchool?.year) {
                    str += ` (${el?.secondarySchool?.year})`;
                  }
                  userData['Secondary School'] = str || '';
                } else {
                  userData['Secondary School'] = '';
                }
                if (el?.graduationDegree?.course) {
                  let str = el?.graduationDegree?.course;
                  if (
                    el?.graduationDegree?.institution &&
                    el?.graduationDegree?.yearOfCompletion
                  ) {
                    str += ` (${el?.graduationDegree?.institution} - ${el?.graduationDegree?.yearOfCompletion})`;
                  } else if (el?.graduationDegree?.institution) {
                    str += ` (${el?.graduationDegree?.institution})`;
                  } else if (el?.graduationDegree?.yearOfCompletion) {
                    str += ` (${el?.graduationDegree?.yearOfCompletion})`;
                  }
                  userData['Graduation Degree'] = str || '';
                } else {
                  userData['Graduation Degree'] = '';
                }
                if (el?.postGraduationDegree?.course) {
                  let str = el?.postGraduationDegree?.course;
                  if (
                    el?.postGraduationDegree?.institution &&
                    el?.postGraduationDegree?.yearOfCompletion
                  ) {
                    str += ` (${el?.postGraduationDegree?.institution} - ${el?.postGraduationDegree?.yearOfCompletion})`;
                  } else if (el?.postGraduationDegree?.institution) {
                    str += ` (${el?.postGraduationDegree?.institution})`;
                  } else if (el?.postGraduationDegree?.yearOfCompletion) {
                    str += ` (${el?.postGraduationDegree?.yearOfCompletion})`;
                  }
                  userData['Post-Graduation Degree'] = str || '';
                } else {
                  userData['Post-Graduation Degree'] = '';
                }
                userData['Higher Degree'] =
                  el?.higherDegrees?.institution || '';
                userData['Food Habits'] = el?.eatingHabits || '';
                userData['Fitness Outlook'] = el?.fitnessOutlook || '';
                userData['Personality'] = el?.personality || '';
                userData['Ideal Weekend'] = el?.idealWeekend || '';
                userData['Smokes'] = el?.smoke || '';
                userData['Drink Alcohol'] = el?.drinkAlcohol || '';
                userData['Family Type'] = el?.familyDescription || '';
                userData['Father Profession'] = el?.fatherProfession || '';
                userData['Father Industry'] = el?.fatherIndustry || '';
                userData['Mother Profession'] = el?.motherProfession || '';
                userData['Mother Industry'] = el?.motherIndustry || '';
                userData['Siblings'] = el?.siblings?.join(', ') || '';
                userData['Annual Family Income'] = el?.annualFamilyIncome || '';
                userData['Preference for kids'] = el?.wantKids || '';
                userData['Preference for Living Arrangement'] =
                  el?.livingArrangementPreference || '';
                userData['Open to relocate'] =
                  el?.relocationCities?.length > 0
                    ? `Open to relocate to - ${el.relocationCities
                        ?.map((el) => el.name)
                        ?.join(', ')}`
                    : '';
                userData['Describe yourself in 3 words'] = el?.describe || '';
                userData['What are you most proud of?'] = el?.proudOf || '';
                temp.push(userData);
              }
            });
            setExcelData([...temp]);
            setHeaders([
              ...Object.keys(temp[0]).map((el) => ({
                label: el,
                key: el,
              })),
            ]);
          } else {
            setHeaders([]);
            setExcelData([]);
          }
          setLoading(false);
        } catch (error) {
          if (error?.response?.status === 401) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.removeAuthentication();
            }, 1000);
          } else if (error?.response?.status === 403) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.reirectToUsers();
            }, 1000);
          }
        }
      } else {
        setLoading(false);
      }
    },
    [page, searchUser, requestType, search, viewData]
  );

  const handleSearchUser = (e) => {
    const value = e.target.value;
    setSearchUser(value);
  };

  const fetch = useCallback(
    (num) => {
      fetchUsers(num || page);
      fetchRMAdmins();
      fetchSubscriptions();
    },
    [requestType, page]
  );

  const upgradeUserPlanHandler = useCallback(async (userId, subscriptionId) => {
    try {
      await upgradeUserPlan(userId, subscriptionId);
      fetchUsers();
      toast.success('User subscription updated successfully');
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else if (error?.response?.status === 400) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (page) {
      setLoading(true);
      fetch(page);
    } else {
      navigate('/users?page=1&user=');
    }
  }, [page, requestType, search]);

  const assignRM = useCallback(async (id, assignedRM) => {
    try {
      await updateUserRM(id, assignedRM);
      fetchUsers();
      toast.success('Relationship Manager assigned successfully');
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, []);

  const activateDeactivateUser = useCallback(async (id, isActive) => {
    try {
      await updateUser(id, { isActive });
      fetchUsers();
      toast.success(
        isActive
          ? 'User activated assigned successfully'
          : 'User deactivated assigned successfully'
      );
    } catch (error) {
      toast.error('Something went wrong');
    }
  }, []);

  const updatePaperformFilled = useCallback(async (id, isFilled) => {
    try {
      await updateUser(id, { isPaperformFilled: isFilled });
      fetchUsers();
      toast.success('Paperform filled status updated successfully');
    } catch (error) {
      toast.error('Something went wrong');
    }
  }, []);

  const updateRMFilled = useCallback(async (id, isFilled) => {
    try {
      await updateUser(id, { isRMFilled: isFilled });
      fetchUsers();
      toast.success('RMFilled filled status updated successfully');
    } catch (error) {
      toast.error('Something went wrong');
    }
  }, []);

  const openHandler = useCallback((item) => {
    if (item?._id) {
      setViewData(item);
    } else {
      setViewData(null);
    }
  }, []);

  const searchUserHandler = useCallback(() => {
    window.location.href = `/users?page=1&user=${searchUser}`;
  }, [searchUser]);
  const getExpiryDate = (startDate, startTime, pausedDays, duration) => {
    const date = new Date(
      new Date(
        `${startDate.year}-${startDate.month}-${startDate.day} ${startTime.hours}:${startTime.minutes}:${startTime.seconds}`
      ).getTime() +
        (duration + pausedDays) * 1000 * 24 * 60 * 60
    );
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const getUsedAvailableDays = (
    startDate,
    startTime,
    pausedDays,
    duration,
    isAvailable
  ) => {
    const startDateTime = new Date(
      `${startDate.year}-${startDate.month}-${startDate.day} ${startTime.hours}:${startTime.minutes}:${startTime.seconds}`
    ).getTime();
    const endTime =
      startDateTime + (duration + pausedDays) * 1000 * 24 * 60 * 60;

    const currentTime = new Date().getTime();

    let usedDays =
      Math.floor((currentTime - startDateTime) / (1000 * 24 * 60 * 60)) ||
      1 - pausedDays;
    return isAvailable ? duration - usedDays : usedDays;
  };

  const viewAllHandler = useCallback((value) => {
    setRequestType(value);
    setLoading(true);
  }, []);

  return (
    <div className="section">
      {loading && (
        <div className="loader-wrapper">
          <span class="loader" />
        </div>
      )}
      <Add
        open={viewData?._id ? true : false}
        openHandler={openHandler}
        data={viewData}
        fetch={fetchUsers}
      />
      <div className="wrapper">
        <div className="table-header justify-start">
          {user?.role === 'rm' && (
            <div className="input-wrapper">
              <select
                value={requestType}
                onChange={(e) => viewAllHandler(e.target.value)}
              >
                <option value="All">View all</option>
                <option value="Me">Only me</option>
              </select>
            </div>
          )}
          <div className="input-wrapper">
            <input
              type="text"
              placeholder="Search User"
              value={searchUser}
              onChange={(e) => handleSearchUser(e)}
              className="search-input"
            />
          </div>
          <button className="primary" onClick={() => searchUserHandler()}>
            Search
          </button>
          {excelData?.length > 0 && (
            <CSVLink
              headers={headers}
              data={excelData}
              className="button success"
            >
              Download
            </CSVLink>
          )}
        </div>
        {data?.length > 0 && (
          <div className="table-wrapper">
            <table>
              <thead>
                <th>#</th>
                <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Gender</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>City</th>
                <th>Subscription Status</th>
                <th>Subscription Days</th>
                <th>Paused days</th>
                <th>Expiry Date</th>
                <th>See Matches</th>
                <th>Actions</th>
                <th>Active/Inactive</th>
                <th>RM Filled</th>
                <th>Paperform Filled</th>
                <th>Relationship Manager</th>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={item._id}>
                    <td>{(Number(page) - 1) * 20 + index + 1}</td>
                    <td>{item.customerId}</td>
                    <td>{item.name}</td>
                    <td>{item.gender}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.email}</td>
                    <td>{item.currentResidingCity}</td>
                    <td>
                      {item?.userPlan?.plan && !item?.userPlan?.isFreePlan ? (
                        <div className="input-wrapper">
                          <div className="select-wrapper">
                            <select
                              value={
                                subscriptions.find(
                                  (obj) => obj.plan === item?.userPlan?.plan
                                )?._id || ''
                              }
                              onChange={(event) => {
                                upgradeUserPlanHandler(
                                  item._id,
                                  event.target.value
                                );
                              }}
                            >
                              {subscriptions?.map((el) => (
                                <option key={el._id} value={el._id}>
                                  {el.plan}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div className="input-wrapper">
                          <div className="select-wrapper">
                            <select
                              onChange={(event) =>
                                upgradeUserPlanHandler(
                                  item._id,
                                  event.target.value
                                )
                              }
                            >
                              {subscriptions?.map((el) => (
                                <option key={el._id} value={el._id}>
                                  {el.plan}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                    </td>
                    <td>
                      {item?.userPlan?.plan &&
                        !item?.userPlan?.isFreePlan &&
                        `${getUsedAvailableDays(
                          item?.userPlan?.startDate,
                          item?.userPlan?.startTime,
                          item?.userPlan?.pausedDays,
                          item?.userPlan?.duration,
                          false
                        )}/${getUsedAvailableDays(
                          item?.userPlan?.startDate,
                          item?.userPlan?.startTime,
                          item?.userPlan?.pausedDays,
                          item?.userPlan?.duration,
                          true
                        )}`}
                    </td>
                    <td>
                      {item?.userPlan?.plan &&
                        !item?.userPlan?.isFreePlan &&
                        item?.userPlan?.pausedDays}
                    </td>
                    <td>
                      {item?.userPlan?.plan
                        ? getExpiryDate(
                            item?.userPlan?.startDate,
                            item?.userPlan?.startTime,
                            item?.userPlan?.pausedDays,
                            item?.userPlan?.duration
                          )
                        : ''}
                    </td>
                    <td>
                      <Link
                        to={`/matches/${item._id}?gender=${
                          item.gender === 'Male' ? 'Female' : 'Male'
                        }`}
                      >
                        See Matches
                      </Link>
                    </td>
                    <td className="actions">
                      <button
                        className="secondary"
                        onClick={() => navigate(`/users/${item._id}`)}
                      >
                        Edit
                      </button>
                      <button
                        className="info"
                        onClick={() => openHandler(item)}
                      >
                        View
                      </button>
                    </td>
                    <td>
                      <button
                        className={!item.isActive ? 'success' : 'danger'}
                        onClick={() =>
                          activateDeactivateUser(item._id, !item.isActive)
                        }
                      >
                        {item.isActive ? 'Deactivate' : 'Activate'}
                      </button>
                    </td>
                    <td>
                      {item?.isRMFilled ? (
                        'Yes'
                      ) : (
                        <div className="input-wrapper">
                          <select
                            onChange={(event) => {
                              updateRMFilled(item._id, event.target.value);
                            }}
                          >
                            <option value="" disabled selected>
                              Select...
                            </option>
                            <option value="true">Mark as Filled</option>
                          </select>
                        </div>
                      )}
                    </td>
                    <td>
                      {item?.isPaperformFilled ? (
                        'Yes'
                      ) : (
                        <div className="input-wrapper">
                          <select
                            onChange={(event) => {
                              updatePaperformFilled(
                                item._id,
                                event.target.value
                              );
                            }}
                          >
                            <option value="" disabled selected>
                              Select...
                            </option>
                            <option value="true">Mark as Filled</option>
                          </select>
                        </div>
                      )}
                    </td>
                    <td>
                      {user?.role === 'rm' ? (
                        item?.admin?.name
                      ) : (
                        <div className="input-wrapper">
                          <select
                            value={item?.admin?._id || ''}
                            onChange={(event) =>
                              assignRM(item._id, event.target.value)
                            }
                          >
                            <option
                              key={item._id + index}
                              value=""
                              disabled
                              style={{ display: 'none' }}
                            ></option>
                            {admins?.map((admin) => (
                              <option
                                key={item.Add_d + admin._id}
                                value={admin._id}
                              >
                                {admin.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-footer">
              {!pageInfo?.prevPage ? (
                <button className="secondary disabled">Prev</button>
              ) : (
                <Link to={`/users?page=${pageInfo.prevPage}`}>
                  <button className="secondary">Prev</button>
                </Link>
              )}
              {!pageInfo?.nextPage ? (
                <button className="secondary disabled">Next</button>
              ) : (
                <Link to={`/users?page=${pageInfo.nextPage}`}>
                  <button className="secondary">Next</button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;
