import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { addMatchSyncBracket, editMatchSyncBracket } from "../../apis/users";
import authentication from "../../utils/authentication";

const AddBracket = ({ data, fetch, editData, setEditData }) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    name: editData?.name || "",
    type: editData?.type || "",
    tag: editData?.tag || "",
    startValue: editData?.startValue || 0,
    endValue: editData?.endValue || 0,
  });
  const openHandler = useCallback(() => {
    setOpen(!open);
    if (open) {
      setEditData(null);
      setState({
        name: "",
        type: "",
        tag: "",
        startValue: 0,
        endValue: 0,
      });
    }
  }, [open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (editData?._id) {
      setOpen(true);
      setState({
        name: editData?.name || "",
        type: editData?.type || "",
        tag: editData?.tag || "",
        startValue: editData?.startValue || 0,
        endValue: editData?.endValue || 0,
      });
    }
  }, [editData]);

  const submitHandler = useCallback(async () => {
    try {
      let cleanData = {
        name: state?.name?.trim() || "",
        type: state?.type?.trim() || "",
        tag: state?.tag?.trim() || "",
        startValue: state?.startValue || 0,
        endValue: state?.endValue || 0,
      };

      if (!cleanData.name) {
        toast.error("Name is required");
        return;
      } else if (!cleanData.tag) {
        toast.error("Tag is required");
        return;
      } else if (!cleanData.type) {
        toast.error("Type is required");
        return;
      } else if (cleanData.startValue === cleanData.endValue) {
        toast.error("Start value and end value cannot be same");
        return;
      } else if (data.length > 0) {
        const duplicates = data
          ?.filter((el) => editData?._id !== el._id)
          ?.filter(
            (item) =>
              (item?.startValue >= cleanData.startValue &&
                item?.startValue <= cleanData.endValue) ||
              (item?.endValue >= cleanData.startValue &&
                item?.endValue <= cleanData.endValue)
          );
        if (duplicates?.length > 0) {
          toast.error(
            "Start value or end value is conflicting with other brackets"
          );
          return;
        } else {
          if (editData?._id) {
            await editMatchSyncBracket(editData?._id, cleanData);
            toast.success(
              "Bracket updated successfully. Please remember to update other brackets too!"
            );
          } else {
            await addMatchSyncBracket(cleanData);
            toast.success("Bracket added successfully");
          }
          fetch();
          setOpen(false);
        }
      } else {
        if (editData?._id) {
          await editMatchSyncBracket(editData?._id, cleanData);
          toast.success(
            "Bracket updated successfully. Please remember to update other brackets too!"
          );
        } else {
          await addMatchSyncBracket(cleanData);
          toast.success("Bracket added successfully");
        }
        fetch();
        setOpen(false);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [state]);
  return (
    <div className="modal-container">
      {/* <div className="add-modal">
        <button className="primary" onClick={openHandler}>
          Add Bracket
        </button>
      </div> */}
      <div className={open ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header">
            <h2>{editData?._id ? "Edit Bracket" : "Add Bracket"}</h2>
            <button type="button" className="danger" onClick={openHandler}>
              Close
            </button>
          </div>
          <div className="content">
            <div className="form-section">
              <div className="input-wrapper">
                <label>
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={state.name}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Name"
                />
              </div>
              <div className="input-wrapper">
                <label>
                  Tag <span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="tag"
                  value={state.tag}
                  onChange={(e) => handleInputChange(e)}
                  placeholder="Tag"
                />
              </div>
              {!editData?._id && (
                <div className="input-wrapper">
                  <label>
                    Type <span className="required">*</span>
                  </label>
                  <select
                    name="type"
                    value={state.type}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option
                      value=""
                      disabled
                      style={{ display: "none" }}
                    ></option>
                    <option value="Our Sync">Our Sync</option>
                    <option value="My Sync">My Sync</option>
                  </select>
                </div>
              )}
              <div className="input-wrapper">
                <label>
                  Start Value <span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="startValue"
                  value={state.startValue}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className="input-wrapper">
                <label>
                  End Value <span className="required">*</span>
                </label>
                <input
                  type="number"
                  name="endValue"
                  value={state.endValue}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="footer">
            <button
              onClick={
                state.name?.trim() === editData?.name &&
                state.tag?.trim() === editData?.tag &&
                state.startValue === editData?.startValue &&
                state.endValue === editData?.endValue
                  ? null
                  : () => submitHandler()
              }
              type="button"
              className={
                state.name?.trim() === editData?.name &&
                state.tag?.trim() === editData?.tag &&
                state.startValue === editData?.startValue &&
                state.endValue === editData?.endValue
                  ? "primary disabled"
                  : "primary"
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBracket;
