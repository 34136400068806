import React, { useEffect, useState, useCallback } from "react";
import { getUser, updateUserDetailsAndPreferences } from "../../apis/users";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const countryCodes = [
  {
    country: "Afghanistan",
    code: "+93",
  },
  {
    country: "Albania",
    code: "+355",
  },
  {
    country: "Algeria",
    code: "+213",
  },
  {
    country: "Andorra",
    code: "+376",
  },
  {
    country: "Angola",
    code: "+244",
  },
  {
    country: "Argentina",
    code: "+54",
  },
  {
    country: "Armenia",
    code: "+374",
  },
  {
    country: "Australia",
    code: "+61",
  },
  {
    country: "Austria",
    code: "+43",
  },
  {
    country: "Azerbaijan",
    code: "+994",
  },
  {
    country: "Bahamas",
    code: "+1-242",
  },
  {
    country: "Bahrain",
    code: "+973",
  },
  {
    country: "Bangladesh",
    code: "+880",
  },
  {
    country: "Barbados",
    code: "+1-246",
  },
  {
    country: "Belarus",
    code: "+375",
  },
  {
    country: "Belgium",
    code: "+32",
  },
  {
    country: "Belize",
    code: "+501",
  },
  {
    country: "Benin",
    code: "+229",
  },
  {
    country: "Bhutan",
    code: "+975",
  },
  {
    country: "Bolivia",
    code: "+591",
  },
  {
    country: "Bosnia and Herzegovina",
    code: "+387",
  },
  {
    country: "Botswana",
    code: "+267",
  },
  {
    country: "Brazil",
    code: "+55",
  },
  {
    country: "Brunei",
    code: "+673",
  },
  {
    country: "Bulgaria",
    code: "+359",
  },
  {
    country: "Burkina Faso",
    code: "+226",
  },
  {
    country: "Burundi",
    code: "+257",
  },
  {
    country: "Cambodia",
    code: "+855",
  },
  {
    country: "Cameroon",
    code: "+237",
  },
  {
    country: "Canada",
    code: "+1",
  },
  {
    country: "Cape Verde",
    code: "+238",
  },
  {
    country: "Central African Republic",
    code: "+236",
  },
  {
    country: "Chad",
    code: "+235",
  },
  {
    country: "Chile",
    code: "+56",
  },
  {
    country: "China",
    code: "+86",
  },
  {
    country: "Colombia",
    code: "+57",
  },
  {
    country: "Comoros",
    code: "+269",
  },
  {
    country: "Congo",
    code: "+242",
  },
  {
    country: "Congo (Democratic Republic)",
    code: "+243",
  },
  {
    country: "Costa Rica",
    code: "+506",
  },
  {
    country: "Croatia",
    code: "+385",
  },
  {
    country: "Cuba",
    code: "+53",
  },
  {
    country: "Cyprus",
    code: "+357",
  },
  {
    country: "Czech Republic",
    code: "+420",
  },
  {
    country: "Denmark",
    code: "+45",
  },
  {
    country: "Djibouti",
    code: "+253",
  },
  {
    country: "Dominica",
    code: "+1-767",
  },
  {
    country: "Dominican Republic",
    code: "+1-809",
  },
  {
    country: "Ecuador",
    code: "+593",
  },
  {
    country: "Egypt",
    code: "+20",
  },
  {
    country: "El Salvador",
    code: "+503",
  },
  {
    country: "Equatorial Guinea",
    code: "+240",
  },
  {
    country: "Eritrea",
    code: "+291",
  },
  {
    country: "Estonia",
    code: "+372",
  },
  {
    country: "Eswatini",
    code: "+268",
  },
  {
    country: "Ethiopia",
    code: "+251",
  },
  {
    country: "Fiji",
    code: "+679",
  },
  {
    country: "Finland",
    code: "+358",
  },
  {
    country: "France",
    code: "+33",
  },
  {
    country: "Gabon",
    code: "+241",
  },
  {
    country: "Gambia",
    code: "+220",
  },
  {
    country: "Georgia",
    code: "+995",
  },
  {
    country: "Germany",
    code: "+49",
  },
  {
    country: "Ghana",
    code: "+233",
  },
  {
    country: "Greece",
    code: "+30",
  },
  {
    country: "Grenada",
    code: "+1-473",
  },
  {
    country: "Guatemala",
    code: "+502",
  },
  {
    country: "Guinea",
    code: "+224",
  },
  {
    country: "Guinea-Bissau",
    code: "+245",
  },
  {
    country: "Guyana",
    code: "+592",
  },
  {
    country: "Haiti",
    code: "+509",
  },
  {
    country: "Honduras",
    code: "+504",
  },
  {
    country: "Hungary",
    code: "+36",
  },
  {
    country: "Iceland",
    code: "+354",
  },
  {
    country: "India",
    code: "+91",
  },
  {
    country: "Indonesia",
    code: "+62",
  },
  {
    country: "Iran",
    code: "+98",
  },
  {
    country: "Iraq",
    code: "+964",
  },
  {
    country: "Ireland",
    code: "+353",
  },
  {
    country: "Israel",
    code: "+972",
  },
  {
    country: "Italy",
    code: "+39",
  },
  {
    country: "Ivory Coast",
    code: "+225",
  },
  {
    country: "Jamaica",
    code: "+1-876",
  },
  {
    country: "Japan",
    code: "+81",
  },
  {
    country: "Jordan",
    code: "+962",
  },
  {
    country: "Kazakhstan",
    code: "+7",
  },
  {
    country: "Kenya",
    code: "+254",
  },
  {
    country: "Kiribati",
    code: "+686",
  },
  {
    country: "Kuwait",
    code: "+965",
  },
  {
    country: "Kyrgyzstan",
    code: "+996",
  },
  {
    country: "Laos",
    code: "+856",
  },
  {
    country: "Latvia",
    code: "+371",
  },
  {
    country: "Lebanon",
    code: "+961",
  },
  {
    country: "Lesotho",
    code: "+266",
  },
  {
    country: "Liberia",
    code: "+231",
  },
  {
    country: "Libya",
    code: "+218",
  },
  {
    country: "Liechtenstein",
    code: "+423",
  },
  {
    country: "Lithuania",
    code: "+370",
  },
  {
    country: "Luxembourg",
    code: "+352",
  },
  {
    country: "Madagascar",
    code: "+261",
  },
  {
    country: "Malawi",
    code: "+265",
  },
  {
    country: "Malaysia",
    code: "+60",
  },
  {
    country: "Maldives",
    code: "+960",
  },
  {
    country: "Mali",
    code: "+223",
  },
  {
    country: "Malta",
    code: "+356",
  },
  {
    country: "Marshall Islands",
    code: "+692",
  },
  {
    country: "Mauritania",
    code: "+222",
  },
  {
    country: "Mauritius",
    code: "+230",
  },
  {
    country: "Mexico",
    code: "+52",
  },
  {
    country: "Micronesia",
    code: "+691",
  },
  {
    country: "Moldova",
    code: "+373",
  },
  {
    country: "Monaco",
    code: "+377",
  },
  {
    country: "Mongolia",
    code: "+976",
  },
  {
    country: "Montenegro",
    code: "+382",
  },
  {
    country: "Morocco",
    code: "+212",
  },
  {
    country: "Mozambique",
    code: "+258",
  },
  {
    country: "Myanmar",
    code: "+95",
  },
  {
    country: "Namibia",
    code: "+264",
  },
  {
    country: "Nauru",
    code: "+674",
  },
  {
    country: "Nepal",
    code: "+977",
  },
  {
    country: "Netherlands",
    code: "+31",
  },
  {
    country: "New Zealand",
    code: "+64",
  },
  {
    country: "Nicaragua",
    code: "+505",
  },
  {
    country: "Niger",
    code: "+227",
  },
  {
    country: "Nigeria",
    code: "+234",
  },
  {
    country: "North Korea",
    code: "+850",
  },
  {
    country: "North Macedonia",
    code: "+389",
  },
  {
    country: "Norway",
    code: "+47",
  },
  {
    country: "Oman",
    code: "+968",
  },
  {
    country: "Pakistan",
    code: "+92",
  },
  {
    country: "Palau",
    code: "+680",
  },
  {
    country: "Palestine",
    code: "+970",
  },
  {
    country: "Panama",
    code: "+507",
  },
  {
    country: "Papua New Guinea",
    code: "+675",
  },
  {
    country: "Paraguay",
    code: "+595",
  },
  {
    country: "Peru",
    code: "+51",
  },
  {
    country: "Philippines",
    code: "+63",
  },
  {
    country: "Poland",
    code: "+48",
  },
  {
    country: "Portugal",
    code: "+351",
  },
  {
    country: "Qatar",
    code: "+974",
  },
  {
    country: "Romania",
    code: "+40",
  },
  {
    country: "Russia",
    code: "+7",
  },
  {
    country: "Rwanda",
    code: "+250",
  },
  {
    country: "Saint Kitts and Nevis",
    code: "+1-869",
  },
  {
    country: "Saint Lucia",
    code: "+1-758",
  },
  {
    country: "Saint Vincent and the Grenadines",
    code: "+1-784",
  },
  {
    country: "Samoa",
    code: "+685",
  },
  {
    country: "San Marino",
    code: "+378",
  },
  {
    country: "Sao Tome and Principe",
    code: "+239",
  },
  {
    country: "Saudi Arabia",
    code: "+966",
  },
  {
    country: "Senegal",
    code: "+221",
  },
  {
    country: "Serbia",
    code: "+381",
  },
  {
    country: "Seychelles",
    code: "+248",
  },
  {
    country: "Sierra Leone",
    code: "+232",
  },
  {
    country: "Singapore",
    code: "+65",
  },
  {
    country: "Slovakia",
    code: "+421",
  },
  {
    country: "Slovenia",
    code: "+386",
  },
  {
    country: "Solomon Islands",
    code: "+677",
  },
  {
    country: "Somalia",
    code: "+252",
  },
  {
    country: "South Africa",
    code: "+27",
  },
  {
    country: "South Korea",
    code: "+82",
  },
  {
    country: "South Sudan",
    code: "+211",
  },
  {
    country: "Spain",
    code: "+34",
  },
  {
    country: "Sri Lanka",
    code: "+94",
  },
  {
    country: "Sudan",
    code: "+249",
  },
  {
    country: "Suriname",
    code: "+597",
  },
  {
    country: "Sweden",
    code: "+46",
  },
  {
    country: "Switzerland",
    code: "+41",
  },
  {
    country: "Syria",
    code: "+963",
  },
  {
    country: "Taiwan",
    code: "+886",
  },
  {
    country: "Tajikistan",
    code: "+992",
  },
  {
    country: "Tanzania",
    code: "+255",
  },
  {
    country: "Thailand",
    code: "+66",
  },
  {
    country: "Timor-Leste",
    code: "+670",
  },
  {
    country: "Togo",
    code: "+228",
  },
  {
    country: "Tonga",
    code: "+676",
  },
  {
    country: "Trinidad and Tobago",
    code: "+1-868",
  },
  {
    country: "Tunisia",
    code: "+216",
  },
  {
    country: "Turkey",
    code: "+90",
  },
  {
    country: "Turkmenistan",
    code: "+993",
  },
  {
    country: "Tuvalu",
    code: "+688",
  },
  {
    country: "Uganda",
    code: "+256",
  },
  {
    country: "Ukraine",
    code: "+380",
  },
  {
    country: "United Arab Emirates",
    code: "+971",
  },
  {
    country: "United Kingdom",
    code: "+44",
  },
  {
    country: "United States",
    code: "+1",
  },
  {
    country: "Uruguay",
    code: "+598",
  },
  {
    country: "Uzbekistan",
    code: "+998",
  },
  {
    country: "Vanuatu",
    code: "+678",
  },
  {
    country: "Vatican City",
    code: "+39",
  },
  {
    country: "Venezuela",
    code: "+58",
  },
  {
    country: "Vietnam",
    code: "+84",
  },
  {
    country: "Yemen",
    code: "+967",
  },
  {
    country: "Zambia",
    code: "+260",
  },
  {
    country: "Zimbabwe",
    code: "+263",
  },
];

const UserDetails = () => {
  const id = useParams()?.id;
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState();
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    gender: "",
    placeOfBirth: "",
    nationality: "",
    currentResidingCity: "",
    country: "",
    dateOfBirth: {
      year: 0,
      month: 0,
      date: 0,
    },
    religion: "",
    height: "",
    motherTongue: "",
    secondarySchool: {
      name: "",
      yearOfGraduation: "",
      city: "",
    },
    graduationDegree: {
      institution: "",
      yearOfCompletion: "",
      course: "",
      city: "",
    },
    postGraduationDegree: {
      completed: "",
      institution: "",
      yearOfCompletion: "",
      course: "",
      city: "",
    },
    higherDegrees: {
      completed: "",
      institution: "",
      yearOfCompletion: "",
      city: "",
      course: "",
    },
    workDetails: {
      description: "",
      company: "",
      designation: "",
      annualEarning: "",
    },
    fitnessOutlook: "",
    maritalStatus: "",
    livingArrangement: "",
    personality: "",
    motherProfession: "",
    motherIndustry: "",
    fatherProfession: "",
    fatherIndustry: "",
    annualFamilyIncome: "",
    familyDescription: "",
    siblings: "",
    kundliMatching: "",
    idealWeekend: "",
    eatingHabits: "",
    foodOutlook: "",
    fitnessOutlook: "",
    drinkAlcohol: "",
    smoke: "",
    internationalTravel: "",
    livedInternationally: "",
    relationship: "",
    additionalComments: "",
    communicationStyle: "",
    spontaneousOrPlanner: "",
    proudOf: "",
    describe: "",
    holidayDestination: "",
    movieGenre: "",
    roleModel: "",
    birthTime: "",
    isTier: "",
    haveKids: "",
    wantKids: "",
    timelineExpectation: "",
    comments: "",
  });
  const fetchUserDetails = useCallback(async (id) => {
    try {
      const apiResponse = await getUser(id);
      if (apiResponse?.data?.data?.user) {
        setUser({
          name: apiResponse?.data?.data?.user?.name || "",
          email: apiResponse?.data?.data?.user?.email || "",
          phoneNumber: apiResponse?.data?.data?.user?.phoneNumber || "",
          gender: apiResponse?.data?.data?.user?.gender || "",
          placeOfBirth: apiResponse?.data?.data?.user?.placeOfBirth || "",
          nationality: apiResponse?.data?.data?.user?.nationality || "",
          country: apiResponse?.data?.data?.user?.country || "",
          currentResidingCity:
            apiResponse?.data?.data?.user?.currentResidingCity || "",
          dateOfBirth: {
            year: apiResponse?.data?.data?.user?.dateOfBirth?.year || 0,
            month: apiResponse?.data?.data?.user?.dateOfBirth?.month || 0,
            date: apiResponse?.data?.data?.user?.dateOfBirth?.date || 0,
          },
          fitnessOutlook: apiResponse?.data?.data?.user?.fitnessOutlook || "",
          religion: apiResponse?.data?.data?.user?.religion || "",
          height: apiResponse?.data?.data?.user?.height || "",
          motherTongue: apiResponse?.data?.data?.user?.motherTongue || "",
          secondarySchool: {
            name: apiResponse?.data?.data?.user?.secondarySchool?.name,
            yearOfGraduation:
              apiResponse?.data?.data?.user?.secondarySchool
                ?.yearOfGraduation || "",
            city: apiResponse?.data?.data?.user?.secondarySchool?.city || "",
          },
          graduationDegree: {
            institution:
              apiResponse?.data?.data?.user?.graduationDegree?.institution ||
              "",
            yearOfCompletion:
              apiResponse?.data?.data?.user?.graduationDegree
                ?.yearOfCompletion || "",
            course:
              apiResponse?.data?.data?.user?.graduationDegree?.course || "",
            city: apiResponse?.data?.data?.user?.graduationDegree?.city || "",
          },
          postGraduationDegree: {
            completed:
              apiResponse?.data?.data?.user?.postGraduationDegree?.completed ||
              "",
            institution:
              apiResponse?.data?.data?.user?.postGraduationDegree
                ?.institution || "",
            yearOfCompletion:
              apiResponse?.data?.data?.user?.postGraduationDegree
                ?.yearOfCompletion || "",
            course:
              apiResponse?.data?.data?.user?.postGraduationDegree?.course || "",
            city:
              apiResponse?.data?.data?.user?.postGraduationDegree?.city || "",
          },
          higherDegrees: {
            completed:
              apiResponse?.data?.data?.user?.higherDegrees?.completed || "",
            institution:
              apiResponse?.data?.data?.user?.higherDegrees?.institution || "",
            yearOfCompletion:
              apiResponse?.data?.data?.user?.higherDegrees?.yearOfCompletion ||
              "",
            city: apiResponse?.data?.data?.user?.higherDegrees?.city || "",
            course: apiResponse?.data?.data?.user?.higherDegrees?.course || "",
          },
          workDetails: {
            description:
              apiResponse?.data?.data?.user?.workDetails?.description || "",
            company: apiResponse?.data?.data?.user?.workDetails?.company || "",
            designation:
              apiResponse?.data?.data?.user?.workDetails?.designation || "",
            annualEarning:
              apiResponse?.data?.data?.user?.workDetails?.annualEarning || "",
          },
          maritalStatus: apiResponse?.data?.data?.user?.maritalStatus || "",
          livingArrangement:
            apiResponse?.data?.data?.user?.livingArrangement || "",
          personality: apiResponse?.data?.data?.user?.personality || "",
          motherProfession:
            apiResponse?.data?.data?.user?.motherProfession || "",
          motherIndustry: apiResponse?.data?.data?.user?.motherIndustry || "",
          fatherProfession:
            apiResponse?.data?.data?.user?.fatherProfession || "",
          fatherIndustry: apiResponse?.data?.data?.user?.fatherIndustry || "",
          annualFamilyIncome:
            apiResponse?.data?.data?.user?.annualFamilyIncome || "",
          familyDescription:
            apiResponse?.data?.data?.user?.familyDescription || "",
          siblings:
            apiResponse?.data?.data?.user?.siblings
              ?.filter((el) => el.trim())
              ?.join(", ") || "",
          kundliMatching: apiResponse?.data?.data?.user?.kundliMatching || "",
          idealWeekend: apiResponse?.data?.data?.user?.idealWeekend || "",
          eatingHabits: apiResponse?.data?.data?.user?.eatingHabits || "",
          foodOutlook: apiResponse?.data?.data?.user?.foodOutlook || "",
          fitnessOutlook: apiResponse?.data?.data?.user?.fitnessOutlook || "",
          drinkAlcohol: apiResponse?.data?.data?.user?.drinkAlcohol || "",
          smoke: apiResponse?.data?.data?.user?.smoke || "",
          internationalTravel:
            apiResponse?.data?.data?.user?.internationalTravel || "",
          livedInternationally:
            apiResponse?.data?.data?.user?.livedInternationally || "",
          relationship: apiResponse?.data?.data?.user?.relationship || "",
          additionalComments: apiResponse?.data?.data?.user?.additionalComments || "",
          comments: apiResponse?.data?.data?.user?.comments || "",
          communicationStyle:
            apiResponse?.data?.data?.user?.communicationStyle || "",
          spontaneousOrPlanner:
            apiResponse?.data?.data?.user?.spontaneousOrPlanner || "",
          proudOf: apiResponse?.data?.data?.user?.proudOf || "",
          describe: apiResponse?.data?.data?.user?.describe || "",
          holidayDestination:
            apiResponse?.data?.data?.user?.holidayDestination || "",
          movieGenre: apiResponse?.data?.data?.user?.movieGenre || "",
          roleModel: apiResponse?.data?.data?.user?.roleModel || "",
          birthTime: apiResponse?.data?.data?.user?.birthTime || "",
          isTier: apiResponse?.data?.data?.user?.isTier || "",
          haveKids: apiResponse?.data?.data?.user?.haveKids || "",
          wantKids: apiResponse?.data?.data?.user?.wantKids || "",
        });
      }
      console.log("preferences====",preferences)
      setPreferences(apiResponse?.data?.data?.user?.userPreferences);
      setLoading(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchUserDetails(id);
    }
  }, [id]);

  const changeHandler = useCallback(
    (key1, key2, value) => {
      const temp = { ...user };
      if (key2) {
        temp[key1][key2] = value;
      } else {
        temp[key1] = value;
      }
      setUser(temp);
    },
    [user]
  );

  const preferenceChangeHandler = useCallback(
    (index, type, isMultipleSelect, value) => {
      const temp = [...preferences];
      if (type === "relocation") {
        let idx = temp[index]?.selectedOptions.indexOf(value);
        if (idx === -1) {
          temp[index]?.selectedOptions.push(value);
        } else {
          temp[index]?.selectedOptions.splice(idx, 1);
        }
      } else if (type === "scorecard") {
        temp[index].scorecardId = value;
      } else {
        let options = [];
        if (isMultipleSelect === "Yes") {
          temp[index].options?.map((item) => {
            if (item?.name === value) {
              options.push({ ...item, isSelected: !item.isSelected });
            } else {
              options.push(item);
            }
          });
        } else {
          temp[index].options?.map((item) => {
            if (item?.name === value) {
              options.push({ ...item, isSelected: true });
            } else {
              options.push({ ...item, isSelected: false });
            }
          });
        }
        temp[index].options = options;
      }
      setPreferences([...temp]);
    },
    [preferences]
  );

  const submitHandler = useCallback(async () => {
    setLoading(true);
    try {
      await updateUserDetailsAndPreferences(id, {
        user,
        preferences,
      });
      toast.success("User details updated successfully");
      fetchUserDetails(id);
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error(error.response.data.message);
      }
      setLoading(false);
    }
  }, [id, user, preferences]);

  return (
    <div className="section">
      {loading && (
        <div className="loader-wrapper">
          <span class="loader" />
        </div>
      )}
      <div className="wrapper">
        <div className="table-header">
          <button className="primary" onClick={submitHandler}>
            Save
          </button>
        </div>
        <div className="form-section">
          <h4>About Me</h4>
          <div className="flexed">
            <div className="input-wrapper">
              <label>Name</label>
              <input
                value={user?.name}
                onChange={(event) =>
                  changeHandler("name", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Gender</label>
              <select
                value={user?.gender}
                onChange={(event) =>
                  changeHandler("gender", null, event.target.value)
                }
              >
                <option value="" style={{ display: "none" }}>
                  Choose gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>Phone Number</label>
              <input
                value={user?.phoneNumber}
                onChange={(event) =>
                  changeHandler("phoneNumber", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Email</label>
              <input
                value={user?.email}
                onChange={(event) =>
                  changeHandler("email", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Residing City</label>
              <input
                value={user?.currentResidingCity}
                onChange={(event) =>
                  changeHandler("currentResidingCity", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Residing Country</label>
              <select
                value={user?.country}
                onChange={(event) =>
                  changeHandler("country", null, event.target.value)
                }
              >
                <option value="" style={{ display: "none" }}>
                  Choose country
                </option>
                {countryCodes.map((el) => (
                  <option
                    value={el.country}
                  >{`(${el.code}) ${el.country}`}</option>
                ))}
              </select>
            </div>
            <div className="input-wrapper">
              <label>Nationality</label>
              <input
                value={user?.nationality}
                onChange={(event) =>
                  changeHandler("nationality", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Birth date</label>
              <input
                type="number"
                value={user?.dateOfBirth?.date}
                onChange={(event) =>
                  changeHandler("dateOfBirth", "date", event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Birth month</label>
              <input
                type="number"
                value={user?.dateOfBirth?.month}
                onChange={(event) =>
                  changeHandler("dateOfBirth", "month", event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Birth year</label>
              <input
                type="number"
                value={user?.dateOfBirth?.year}
                onChange={(event) =>
                  changeHandler("dateOfBirth", "year", event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Religion</label>
              <input
                value={user?.religion}
                onChange={(event) =>
                  changeHandler("religion", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Height</label>
              <select
                value={user?.height}
                onChange={(event) =>
                  changeHandler("height", null, event.target.value)
                }
              >
                <option value="" style={{ display: "none" }}>
                  Choose height
                </option>
                <option value={`4' 7"`}>{`4' 7"`}</option>
                <option value={`4' 8"`}>{`4' 8"`}</option>
                <option value={`4' 9"`}>{`4' 9"`}</option>
                <option value={`4' 10"`}>{`4' 10"`}</option>
                <option value={`4' 11"`}>{`4' 11"`}</option>
                <option value={`5' 0"`}>{`5' 0"`}</option>
                <option value={`5' 1"`}>{`5' 1"`}</option>
                <option value={`5' 2"`}>{`5' 2"`}</option>
                <option value={`5' 3"`}>{`5' 3"`}</option>
                <option value={`5' 4"`}>{`5' 4"`}</option>
                <option value={`5' 5"`}>{`5' 5"`}</option>
                <option value={`5' 6"`}>{`5' 6"`}</option>
                <option value={`5' 7"`}>{`5' 7"`}</option>
                <option value={`5' 8"`}>{`5' 8"`}</option>
                <option value={`5' 9"`}>{`5' 9"`}</option>
                <option value={`5' 10"`}>{`5' 10"`}</option>
                <option value={`5' 11"`}>{`5' 11"`}</option>
                <option value={`6' 0"`}>{`6' 0"`}</option>
                <option value={`6' 1"`}>{`6' 1"`}</option>
                <option value={`6' 2"`}>{`6' 2"`}</option>
                <option value={`6' 3"`}>{`6' 3"`}</option>
                <option value={`6' 4"`}>{`6' 4"`}</option>
                <option value={`6' 5"`}>{`6' 5"`}</option>
                <option value={`6' 6"`}>{`6' 6"`}</option>
                <option value={`6' 7"`}>{`6' 7"`}</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>Marital Status</label>
              <select
                value={user?.maritalStatus}
                onChange={(event) =>
                  changeHandler("maritalStatus", null, event.target.value)
                }
              >
                <option value="" style={{ display: "none" }}>
                  Choose marital status
                </option>
                <option value="Single">Single</option>
                <option value="Divorced">Divorced</option>
                <option value="Separated">Separated</option>
                <option value="Widowed">Widowed</option>
              </select>
            </div>
            <div className="input-wrapper">
            <label>Do you have any children?</label>

              {/* <label>Do you have children?</label> */}
              <select
                value={user?.haveKids}
                onChange={(event) =>
                  changeHandler("haveKids", null, event.target.value)
                }
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>Your Primary Language</label>
              <input
                value={user?.motherTongue}
                onChange={(event) =>
                  changeHandler("motherTongue", null, event.target.value)
                }
              />
            </div>
            <div className="input-wrapper">
              <label>Birth place</label>
              <input
                onChange={(event) =>
                  changeHandler("placeOfBirth", null, event.target.value)
                }
                value={user?.placeOfBirth}
              />
            </div>
            <div className="input-wrapper">
              <label>How would you describe your mother's profession?</label>
              <input
                onChange={(event) =>
                  changeHandler("motherProfession", null, event.target.value)
                }
                value={user?.motherProfession}
              />
            </div>
            <div className="input-wrapper">
              <label>What industry is your mother working in?</label>
              <input
                onChange={(event) =>
                  changeHandler("motherIndustry", null, event.target.value)
                }
                value={user?.motherIndustry}
              />
            </div>
            <div className="input-wrapper">
              <label>How would you describe your father's profession?</label>
              <input
                onChange={(event) =>
                  changeHandler("fatherProfession", null, event.target.value)
                }
                value={user?.fatherProfession}
              />
            </div>
            <div className="input-wrapper">
              <label>What industry is your father working in?</label>
              <input
                onChange={(event) =>
                  changeHandler("fatherIndustry", null, event.target.value)
                }
                value={user?.fatherIndustry}
              />
            </div>
            <div className="input-wrapper">
              {/* <label>Annual Family Income</label>
               */}
              <label>What is your Annual Family Income?</label>

              <select
                value={user?.annualFamilyIncome}
                onChange={(event) =>
                  changeHandler("annualFamilyIncome", null, event.target.value)
                }
              >
                <option value="" disabled>
                  What is your annual family income bracket?
                </option>
                <option value="<25L">{"<25L"}</option>
                <option value="26L-50L">{"26L-50L"}</option>
                <option value="51 Lacs - 1 Cr">{"51 Lacs - 1 Cr"}</option>
                <option value="1 - 2 Cr">{"1 - 2 Cr"}</option>
                <option value="2 - 5 Cr">{"2 - 5 Cr"}</option>
                <option value="5 - 10 Cr">{"5 - 10 Cr"}</option>
                <option value="> 10 Cr">{"> 10 Cr"}</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>How many siblings does the suitor have?</label>
              <label>How many siblings do you have?</label>

              <input
                onChange={(event) =>
                  changeHandler("siblings", null, event.target.value)
                }
                value={user?.siblings}
              />
            </div>
            {/* <div className="input-wrapper">
              <label>Does your family believe in kundli matching?</label>
              <select
                onChange={(event) =>
                  changeHandler("kundliMatching", null, event.target.value)
                }
                value={user?.kundliMatching}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div> */}
            <div className="input-wrapper">
              <label>Which secondary school did you go to?</label>
              <input
                onChange={(event) =>
                  changeHandler("secondarySchool", "name", event.target.value)
                }
                value={user?.secondarySchool?.name}
              />
            </div>
            <div className="input-wrapper">
              <label>Which city was your secondary school located?</label>
              <input
                onChange={(event) =>
                  changeHandler("secondarySchool", "city", event.target.value)
                }
                value={user?.secondarySchool?.city}
              />
            </div>
            <div className="input-wrapper">
              <label>Which year did you graduate your secondary school?</label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "secondarySchool",
                    "yearOfGraduation",
                    event.target.value
                  )
                }
                value={user?.secondarySchool?.yearOfGraduation}
              />
            </div>
            <div className="input-wrapper">
              <label>
                Which institution did you complete your graduation degree?
              </label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "graduationDegree",
                    "institution",
                    event.target.value
                  )
                }
                value={user?.graduationDegree?.institution}
              />
            </div>
            <div className="input-wrapper">
              <label>Where is your graduation institution located?</label>
              <input
                onChange={(event) =>
                  changeHandler("graduationDegree", "city", event.target.value)
                }
                value={user?.graduationDegree?.city}
              />
            </div>
            <div className="input-wrapper">
              <label>Which year did you complete your graduation degree?</label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "graduationDegree",
                    "yearOfCompletion",
                    event.target.value
                  )
                }
                value={user?.graduationDegree?.yearOfCompletion}
              />
            </div>
            <div className="input-wrapper">
              <label>
                What course did you study in your graduation degree?
              </label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "graduationDegree",
                    "course",
                    event.target.value
                  )
                }
                value={user?.graduationDegree?.course}
              />
            </div>
            <div className="input-wrapper">
              <label>Have you completed a Master's/Post-Grad degree?</label>
              <select
                onChange={(event) =>
                  changeHandler(
                    "postGraduationDegree",
                    "completed",
                    event.target.value
                  )
                }
                value={user?.postGraduationDegree?.completed}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>
                Which institution did you complete your Master's/Post-Grad
                degree?
              </label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "postGraduationDegree",
                    "institution",
                    event.target.value
                  )
                }
                value={user?.postGraduationDegree?.institution}
              />
            </div>
            <div className="input-wrapper">
              <label>
                Where is your Master's/ Post-Grad institution located?
              </label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "postGraduationDegree",
                    "city",
                    event.target.value
                  )
                }
                value={user?.postGraduationDegree?.city}
              />
            </div>
            <div className="input-wrapper">
              <label>
                Which year did you complete your Master's/Post-Grad degree?
              </label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "postGraduationDegree",
                    "yearOfCompletion",
                    event.target.value
                  )
                }
                value={user?.postGraduationDegree?.yearOfCompletion}
              />
            </div>
            <div className="input-wrapper">
              <label>
                Which course did you complete in your Master's/Post-Grad degree?
              </label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "postGraduationDegree",
                    "course",
                    event.target.value
                  )
                }
                value={user?.postGraduationDegree?.course}
              />
            </div>
            <div className="input-wrapper">
              <label>
                Do you have any degrees higher than a Master's/Post-Grad degree?
              </label>
              <select
                onChange={(event) =>
                  changeHandler(
                    "higherDegrees",
                    "completed",
                    event.target.value
                  )
                }
                value={user?.higherDegrees?.completed}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>If yes, from which institution?</label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "higherDegrees",
                    "institution",
                    event.target.value
                  )
                }
                value={user?.higherDegrees?.institution}
              />
            </div>
            <div className="input-wrapper">
              <label>If yes, where is your institution located?</label>
              <input
                onChange={(event) =>
                  changeHandler("higherDegrees", "city", event.target.value)
                }
                value={user?.higherDegrees?.city}
              />
            </div>
            <div className="input-wrapper">
              <label>If yes, which year did you complete your degree?</label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "higherDegrees",
                    "yearOfCompletion",
                    event.target.value
                  )
                }
                value={user?.higherDegrees?.yearOfCompletion}
              />
            </div>
            <div className="input-wrapper">
              <label>If yes, which course?</label>
              <input
                onChange={(event) =>
                  changeHandler("higherDegrees", "course", event.target.value)
                }
                value={user?.higherDegrees?.course}
              />
            </div>
            <div className="input-wrapper">
              <label>Tell us more about your work</label>
              <select
                onChange={(event) =>
                  changeHandler(
                    "workDetails",
                    "description",
                    event.target.value
                  )
                }
                value={user?.workDetails?.description}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Self-employed">Self-employed</option>
                <option value="Employed by a company">
                  Employed by a company
                </option>
                <option value="Not working currently">
                  Not working currently
                </option>
                <option value="Currently studying">Currently studying</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>Which company do you work for?</label>
              <input
                onChange={(event) =>
                  changeHandler("workDetails", "company", event.target.value)
                }
                value={user?.workDetails?.company}
              />
            </div>
            <div className="input-wrapper">
              <label>What is your designation?</label>
              <input
                onChange={(event) =>
                  changeHandler(
                    "workDetails",
                    "designation",
                    event.target.value
                  )
                }
                value={user?.workDetails?.designation}
              />
            </div>
            <div className="input-wrapper">
              <label> What is your current annual earning (INR) ?</label>
              <select
                onChange={(event) =>
                  changeHandler(
                    "workDetails",
                    "annualEarning",
                    event.target.value
                  )
                }
                value={user?.workDetails?.annualEarning}
              >
                <option value="" style={{ display: "none" }}>
                  Choose your income bracket
                </option>
                <option value="< 5 Lacs">{"< 5 Lacs"}</option>
                <option value="5-10 Lacs">{"5-10 Lacs"}</option>
                <option value="10-15 Lacs">{"10-15 Lacs"}</option>
                <option value="15-20 Lacs">{"15-20 Lacs"}</option>
                <option value="20-30 Lacs">{"20-30 Lacs"}</option>
                <option value="30-40 Lacs">{"30-40 Lacs"}</option>
                <option value="40-50 Lacs">{"40-50 Lacs"}</option>
                <option value="50-75 Lacs">{"50-75 Lacs"}</option>
                <option value="75 Lacs - 1 Cr">{"75 Lacs - 1 Cr"}</option>
                <option value="1 - 2 Cr">{"1 - 2 Cr"}</option>
                <option value=">2 Cr">{">2 Cr"}</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>How would you best describe your family?</label>
              <select
                onChange={(event) =>
                  changeHandler("familyDescription", null, event.target.value)
                }
                value={user?.familyDescription}
              >
                <option value="" style={{ display: "none" }}>
                  Choose your family description
                </option>
                <option value="Nuclear family">Nuclear family</option>
                <option value="Joint family">Joint family</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>
                How best would you describe your current living arrangement?
              </label>
              <select
                onChange={(event) =>
                  changeHandler("livingArrangement", null, event.target.value)
                }
                value={user?.livingArrangement}
              >
                <option value="" style={{ display: "none" }}>
                  Choose living arrangement
                </option>
                <option value="Living alone">Living alone</option>
                <option value="Living with parents">Living with parents</option>
                <option value="Living with Joint family">
                  Living with Joint family
                </option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>How would you best describe your personality?</label>
              <select
                onChange={(event) =>
                  changeHandler("personality", null, event.target.value)
                }
                value={user?.personality}
              >
                <option value="" style={{ display: "none" }}>
                  Choose personality
                </option>
                <option value="Extrovert">Extrovert</option>
                <option value="Introvert">Introvert</option>
                <option value="Ambivert">Ambivert</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>What are your eating habits?</label>
              <select
                onChange={(event) =>
                  changeHandler("eatingHabits", null, event.target.value)
                }
                value={user?.eatingHabits}
              >
                <option value="" style={{ display: "none" }}>
                  Choose eating habit
                </option>
                <option value="Vegan">Vegan</option>
                <option value="Veg">Veg</option>
                <option value="Non-veg">Non-veg</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>How often do you workout or stay active?</label>
              <select
                onChange={(event) =>
                  changeHandler("fitnessOutlook", null, event.target.value)
                }
                value={user?.fitnessOutlook}
              >
                <option value="" style={{ display: "none" }}>
                  Select...
                </option>
                <option value="Frequently">Frequently</option>
                <option value="Occasionally">Occasionally</option>
                <option value="Once in a while">Once in a while</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>Do you drink alcohol?</label>
              <select
                onChange={(event) =>
                  changeHandler("drinkAlcohol", null, event.target.value)
                }
                value={user?.drinkAlcohol}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>Do you smoke?</label>
              <select
                onChange={(event) =>
                  changeHandler("smoke", null, event.target.value)
                }
                value={user?.smoke}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* <div className="input-wrapper">
              <label>Which is your ideal holiday?</label>
              <input
                onChange={(event) =>
                  changeHandler("idealWeekend", null, event.target.value)
                }
                value={user?.idealWeekend}
              />
            </div> */}
            <div className="input-wrapper">
              <label>What is your favourite holiday destination?</label>
              <input
                onChange={(event) =>
                  changeHandler("holidayDestination", null, event.target.value)
                }
                value={user?.holidayDestination}
              />
            </div>
            {/* <div className="input-wrapper">
              <label>What is your favourite movie genre?</label>
              <select
                onChange={(event) =>
                  changeHandler("movieGenre", null, event.target.value)
                }
                value={user?.movieGenre}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Action">Action</option>
                <option value="Sci-Fi/Adventure">Sci-Fi/Adventure</option>
                <option value="Rom-Coms">Rom-Coms</option>
                <option value="Comedy">Comedy</option>
                <option value="Drama">Drama</option>
                <option value="Thriller">Thriller</option>
                <option value="I'm not a fan of movies">
                  I'm not a fan of movies
                </option>
              </select>
            </div> */}
            <div className="input-wrapper">
              <label>Who has been a role model in your life and why?</label>
              <input
                onChange={(event) =>
                  changeHandler("roleModel", null, event.target.value)
                }
                value={user?.roleModel}
              />
            </div>
            <div className="input-wrapper">
              <label>What are you are most proud of?</label>
              <input
                onChange={(event) =>
                  changeHandler("proudOf", null, event.target.value)
                }
                value={user?.proudOf}
              />
            </div>
            <div className="input-wrapper">
              <label>How would you describe yourself in 3 words?</label>
              <input
                onChange={(event) =>
                  changeHandler("describe", null, event.target.value)
                }
                value={user?.describe}
              />
            </div>
            {/* <div className="input-wrapper">
              <label>What is your communication style?</label>
              <select onChange={(event) =>
                  changeHandler("motherTongue", null, event.target.value)
                } value={user?.communicationStyle}>
                <option value="" style={{ display: "none" }}></option>
                <option value="I prefer calls">Action</option>
                <option value="I love to text">I love to text</option>
                <option value="Voice notes all the way">
                  Voice notes all the way
                </option>
                <option value="Face to face meet-ups">
                  Face to face meet-ups
                </option>
              </select>
            </div> */}
            {/* <div className="input-wrapper">
              <label>What is your communication style?</label>
              <input
                onChange={(event) =>
                  changeHandler("communicationStyle", null, event.target.value)
                }
                value={user?.communicationStyle}
              />
            </div> */}
            {/* <div className="input-wrapper">
              <label>Are you spontaneous or are you a planner?</label>
              <select
                onChange={(event) =>
                  changeHandler(
                    "spontaneousOrPlanner",
                    null,
                    event.target.value
                  )
                }
                value={user?.spontaneousOrPlanner}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="I plan everything">I plan everything</option>
                <option value="Depends on the situation">
                  Depends on the situation
                </option>
                <option value="I go with the flow">I go with the flow</option>
              </select>
            </div> */}
            <div className="input-wrapper">
              <label>What is your timeline expectation of this journey?</label>
              <select
                onChange={(event) =>
                  changeHandler("timelineExpectation", null, event.target.value)
                }
                value={user?.timelineExpectation}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Prefer to get married within 6-12 months of meeting">
                  Prefer to get married within 6-12 months of meeting
                </option>
                <option value="Prefer to date for 1-2 years">
                  Prefer to date for 1-2 years
                </option>
                <option value="Prefer to take it slow">
                  Prefer to take it slow
                </option>
                <option value="See how the relationship evolves organically">
                  See how the relationship evolves organically
                </option>
              </select>
            </div>
            <div className="input-wrapper">
              <label>
                {/* Did you go a Tier 1 University (RM to judge and respond) */}
                Did you go to a top 10 Indian or global college in your field?
              </label>
              <select
                onChange={(event) =>
                  changeHandler("isTier", null, event.target.value)
                }
                value={user?.isTier}
              >
                <option value="" style={{ display: "none" }}></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="input-wrapper">
              {/* <label>Any other Comments</label> */}
              <label>Are there any other preferences you have that we have not asked?</label>
              <input
                onChange={(event) =>
                  changeHandler("comments", null, event.target.value)
                }
                value={user?.comments}
              />
            </div>
            <div className="input-wrapper">
              <label>Additional Comments</label>
              <input
                onChange={(event) =>
                  changeHandler("additionalComments", null, event.target.value)
                }
                value={user?.additionalComments}
              />
            </div>
          </div>
        </div>
        <div className="form-section" style={{ marginTop: "40px" }}>
          <h4>Preferences</h4>
          <ul className="preferences edit">
            {preferences?.map((item, index) => (
              <li>
                <h5>{item.name}</h5>
                <div className="options">
                  {item.options?.map((el) => (
                    <div className="input-wrapper checkboxes">
                      <input
                        type="checkbox"
                        checked={el.isSelected}
                        onChange={() =>
                          preferenceChangeHandler(
                            index,
                            "options",
                            item.isMultipleSelect,
                            el.name
                          )
                        }
                      />
                      <label>{el.name}</label>
                    </div>
                  ))}
                </div>
                {item?.name === "Open to relocation to a different city" && (
                  <div className="relocation">
                    <h5>If Yes, which one's?</h5>
                    {item?.defaultOptions?.map((city) => (
                      <div className="input-wrapper checkboxes">
                        <input
                          type="checkbox"
                          checked={
                            item?.selectedOptions.indexOf(city._id) === -1
                              ? false
                              : true
                          }
                          onChange={() =>
                            preferenceChangeHandler(
                              index,
                              "relocation",
                              item.isMultipleSelect,
                              city._id
                            )
                          }
                        />
                        <label>{city.name}</label>
                      </div>
                    ))}
                  </div>
                )}
                <div className="scorecards">
                  {item.scorecards?.map((el) => (
                    <button
                      className={el?._id === item.scorecardId ? "selected" : ""}
                      onClick={() =>
                        preferenceChangeHandler(
                          index,
                          "scorecard",
                          item.isMultipleSelect,
                          el._id
                        )
                      }
                    >
                      {el.name}
                    </button>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
