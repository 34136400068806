export default {
  checkLogin: () => {
    if (localStorage.getItem("token")) {
      window.location.href = "/admins";
    }
  },
  checkAuthentication: (path) => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/login";
    }
  },
  removeAuthentication: () => {
    localStorage.removeItem("token");
    window.location.href = "/login";
  },
  reirectToUsers: () => {
    // window.location.href = "/users";
  },
};
