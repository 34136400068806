import { React, useState, useEffect, useCallback } from "react";

import SubscriptionAdd from "./subscriptionAdd";
import "./subscription.css";
import axios from "../../utils/axios";
import SubscriptionTbl from "./SubscriptionTbl";
import ImageUploadComponent from "./asset";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

function Subscription() {
  const initNewSubscriptionData = {
    image: "",
    plan: "",
    slogan: "",
    planTag: "",
    price: "",
    discount: "",
    duration: "",
    features: {
      curatedProfiles: "",
      monthlyCuratedProfiles: "",
      subscriptionPauseDays: "",
      mysyncOrOursync: [],
      benifits: Array(6).fill(""),
    },
  };
  const [data, setdata] = useState([]);
  const [selectedid, setselectedid] = useState("");
  const [addNewSubscription, setAddNewSubscription] = useState(false)
  const [deleteSubscriptionDialogBox, setDeleteSubscriptionDialogBox] = useState(false)
  const [deletePlanData, setDeletePlanData] = useState([])
  const [selecteddeleteid, setSelecteddeleteid] = useState("")
const [newSubscriptionData, setNewSubscriptionData] = useState(initNewSubscriptionData);
  const fetchSubscriptions = async () => {
    try {
      const response = await axios("subscription", "get", {});
      setdata(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };
  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const openHandler = useCallback((id) => {
    if (id) {
      setselectedid(id);
    } else {
      setselectedid(null);
    }
  }, []);

  const openDeleteHandler = useCallback(async (id) => {
    if(id) {
      setDeleteSubscriptionDialogBox(id);
      setSelecteddeleteid(id)
      await fetchDeletePlanData(id);
      console.log(id)
      console.log(selecteddeleteid)
      console.log(deletePlanData)
    } else {
      setDeleteSubscriptionDialogBox(null);
      setSelecteddeleteid("")
    }
  }, [])

  const fetchDeletePlanData = async (id) => {
    try {
      const response = await axios(`subscription/${id}`, "get", {});
      setDeletePlanData(response.data);
      console.log(response.data)
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        console.error(error)
        toast.error("Something went wrong");
      }
    }
  };

  const addHandler = (() => {
   setAddNewSubscription(prev => !prev); 
  });
  
  const deleteViewHandler = (() => {
    setDeleteSubscriptionDialogBox(prev => !prev);
   });
   

  const handleChange = (e, field, nestedField) => {
    console.table(newSubscriptionData);
    if (e === "" && field === "image") {
      setNewSubscriptionData({
        ...newSubscriptionData,
        image: "",
      });
    } else {
      const value =
        e.target.type === "checkbox" ? e.target.checked : e.target.value;

      setNewSubscriptionData((prevData) => {
        let isFreePlan = prevData.isFreePlan;
        if (field === "plan" && value.toLowerCase() === "free") {
          isFreePlan = true;
        } else if (field === "plan") {
          isFreePlan = false;
        } else if (field === "image") {
          return {
            ...prevData,
            [field]: e.target.files[0],
          };
        }

        if (nestedField) {
          if (
            nestedField === "mysyncOrOursync" &&
            e.target.type === "checkbox"
          ) {
            return {
              ...prevData,
              isFreePlan,
              [field]: {
                ...prevData[field],
                [nestedField]: e.target.checked
                  ? [...prevData[field][nestedField], e.target.value]
                  : prevData[field][nestedField].filter(
                      (item) => item !== e.target.value
                    ),
              },
            };
          } else if (nestedField.startsWith("benifit")) {
            const benifitIndex = parseInt(nestedField.slice(-1)) - 1;
            const updatedBenifits = [...prevData.features.benifits];
            updatedBenifits[benifitIndex] = value;

            return {
              ...prevData,
              isFreePlan,
              features: {
                ...prevData.features,
                benifits: updatedBenifits,
                monthlyCuratedProfiles:
                  prevData.features.curatedProfiles /
                  Math.floor(prevData.duration / 30),
              },
            };
          } else {
            return {
              ...prevData,
              isFreePlan,
              [field]: {
                ...prevData[field],
                [nestedField]: value,

                ...(field === "features" &&
                  nestedField === "monthlyCuratedProfiles" && {
                    curatedProfiles:
                      value * Math.floor(Number(prevData.duration) / 30),
                  }),
              },
            };
          }
        } else {
          return {
            ...prevData,
            isFreePlan,
            [field]: value,
            ...(field === "duration" && {
              features: {
                ...prevData.features,
                curatedProfiles:
                  Number(prevData.features.monthlyCuratedProfiles) *
                  Math.floor(Number(value) / 30),
              },
            }),
          };
        }
      });
    }
  };

  const fileChangeHandler = useCallback(
    async (e) => {
      if (e?.target?.files[0]) {
        try {
          const token = localStorage.getItem("token");
          console.log(selectedid);
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          };
          const form = new FormData();
          form.append("file", e?.target?.files[0]);
          const res = await axios("users/video", "post", form, headers);
          console.log(`1111${res}`);
          
          await axios(`subscription/edit/${selectedid}`, "put", {
            image: res.data.url,
          });
          setNewSubscriptionData((prev) => ({
            ...prev,
            image: res.data.url,
          }));
          toast.success("Subscription image uploaded successfully");
        } catch (error) {
          console.error(error)
          if (error?.response?.status === 401) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.removeAuthentication();
            }, 1000);
          } else if (error?.response?.status === 403) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.reirectToUsers();
            }, 1000);
          } else {
            toast.error("Something went wrong while uploading image");
          }
        }
      }
    },
    []
  );

  const submitHandler = useCallback(async () => {
    if (newSubscriptionData?.plan?.trim() === "") {
      toast.error("Subscription name is required");
      return;
    }
    if (Number.isInteger(newSubscriptionData.monthlyCuratedProfiles)) {
      toast.error("Monthlty Curated Profile Must be in Integer");
      return;
    }

    if (
      newSubscriptionData.features.benifits[0]?.length > 40 ||
      newSubscriptionData.features.benifits[1]?.length > 40 ||
      newSubscriptionData.features.benifits[2]?.length > 40 ||
      newSubscriptionData.features.benifits[3]?.length > 40 ||
      newSubscriptionData.features.benifits[4]?.length > 40 ||
      newSubscriptionData.features.benifits[5]?.length > 40
    ) {
      toast.error("benifit should be in 40 characters or less");
      return;
    }
    try {
      await axios(`subscription/create`, "post", newSubscriptionData);

      toast.success("Subscription created successfully");
      addHandler();
      fetchSubscriptions();
setNewSubscriptionData(initNewSubscriptionData)
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, [newSubscriptionData]);

  const deleteHandler = useCallback(async (id) => {
    try {
      await axios(`subscription/delete/${id}`, "DELETE", {});

      toast.success("Subscription deleted successfully");
      fetchSubscriptions();
      setDeletePlanData([])
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
    setDeleteSubscriptionDialogBox(false);
  }, [])

  return (
    <div className="section">
      <div className="wrapper">
        <div style={{
            display:"flex",
            alignContent:"center",
            justifyContent:"end",}}>
      <button className="primary" onClick={addHandler}>
          Add New Subscription
        </button>
        </div>
      <div className="modal-container">
            <div className="add-modal" style={{
            marginBottom: "1em",
            display:"flex",
            alignContent:"center",
            justifyContent:"end",
          }}>
        
      </div>

      {/* add new subscription modal */}
      <div className={addNewSubscription ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header" >
            <h2>Add New Subscription</h2>
            <button type="button" className="danger" onClick={addHandler}>
              Close
            </button>
          </div>
            <div className="content">
              <div className="input-wrapper">
                <div className="form-section">
                  <h4>Icon</h4>
                  <ImageUploadComponent
                    type={`subscription-add-${selectedid}`}
                    // value={newSubscriptionData?.image}
                    value={newSubscriptionData.image}
                    onChange={fileChangeHandler}
                  />
                </div>
              </div>
              <div className="form-section">
                <h4> Sync Options</h4>
                <div className="checkboxes">
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      value="MySync"
                      checked={newSubscriptionData.features.mysyncOrOursync.includes("MySync")}
                      onChange={(e) =>
                        handleChange(e, "features", "mysyncOrOursync")
                      }
                    />
                    <label>My Sync</label>
                  </div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      value="OurSync"
                      checked={newSubscriptionData.features.mysyncOrOursync.includes(
                        "OurSync"
                      )}
                      onChange={(e) =>
                        handleChange(e, "features", "mysyncOrOursync")
                      }
                    />
                    <label>Our Sync</label>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <h4>Information</h4>

                <div className="input-wrapper">
                  <label>
                    Name <span className="required">*</span>
                  </label>
                  <input
                    value={newSubscriptionData.plan}
                    onChange={(e) => handleChange(e, "plan")}
                  />
                </div>

                <div className="input-wrapper">
                  <label>
                    Slogan <span className=""></span>
                  </label>
                  <input
                    value={newSubscriptionData.slogan}
                    onChange={(e) => handleChange(e, "slogan")}
                  />
                </div>
                <div className="input-wrapper">
                  <label>
                    Tag <span className="required">*</span>
                  </label>
                  <input
                    value={newSubscriptionData.planTag}
                    onChange={(e) => handleChange(e, "planTag")}
                  />
                </div>

                <div className="flexed">
                  {!newSubscriptionData?.isFreePlan && (
                    <>
                      <div className="input-wrapper">
                        <label>
                          Price <span className="required">*</span>
                        </label>
                        <input
                          value={newSubscriptionData.price}
                          onChange={(e) => handleChange(e, "price")}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label>
                          Discount <span className="required">*</span>
                        </label>
                        <input
                          value={newSubscriptionData.discount}
                          onChange={(e) => handleChange(e, "discount")}
                        />
                      </div>
                    </>
                  )}
                  <div className="input-wrapper">
                    <label>
                      Duration <span className="required">*</span>
                    </label>

                    <select
                      style={{ width: "200px" }}
                      value={newSubscriptionData.duration}
                      onChange={(e) => handleChange(e, "duration")}
                    >
                      <option value="">select duration</option>
                      <option value="30">1 Months</option>
                      <option value="60">2 Months</option>
                      <option value="90">3 Months</option>
                      <option value="120">4 Months</option>
                      <option value="150">5 Months</option>
                      <option value="180">6 Months</option>
                      <option value="210">7 Months</option>
                      <option value="240">8 Months</option>
                      <option value="270">9 Months</option>
                      <option value="300">10 Months</option>
                      <option value="330">11 Months</option>
                      <option value="365">1 year</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <h4>Features</h4>

                <div className="flexed">
                  <div className="input-wrapper">
                    <label>
                      Curated Profiles <span className="required">*</span>
                    </label>
                    <input 
                    value={newSubscriptionData.features.curatedProfiles}
                     />
                  </div>

                  <div className="input-wrapper">
                    <label>
                      Monthly Curated Profiles{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      value={newSubscriptionData.features.monthlyCuratedProfiles}
                      onChange={(e) =>
                        handleChange(e, "features", "monthlyCuratedProfiles")
                      }
                    />
                  </div>

                  <div className="input-wrapper">
                    <label>
                      Subscription Pause Days{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      value={newSubscriptionData.features.subscriptionPauseDays}
                      onChange={(e) =>
                        handleChange(e, "features", "subscriptionPauseDays")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-section">
                <h4>Benifits</h4>

                {[1, 2, 3, 4, 5, 6].map((index) => (
                  <div className="input-wrapper" key={index}>
                    <label>Benifit {index}</label>
                    <input
                      value={newSubscriptionData.features.benifits[index - 1]}
                      onChange={(e) =>
                        handleChange(e, "features", `benifit${index}`)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="footer">
              <button 
              onClick={submitHandler}
               type="button" className="primary">
                {selectedid ? `Update` : `Submit`}
              </button>
            </div>
          </div>
      </div>

      <div className={deleteSubscriptionDialogBox ? "modal active" : "modal"}>
        <div className="wrapper">
          <div className="header" >
            <h2>Delete Subscription</h2>
            <button type="button" className="danger" onClick={deleteViewHandler}>
              Close
            </button>
          </div>
            <div className="content">
              
              {/* <div className="input-wrapper">
                <div className="form-section">
                  <h4>Icon</h4>
                  <ImageUploadComponent
                    // type={}
                    // value={newSubscriptionData?.image}
                    value={deletePlanData.image}
                    // onChange={fileChangeHandler}
                  />
                </div>
              </div> */}
              <div className="form-section">
                <h4>Information</h4>

                <div className="input-wrapper">
                  <label>
                    Name <span className="required">*</span>
                  </label>
                  <input
                    value={deletePlanData.plan}
                  />
                </div>

                <div className="input-wrapper">
                  <label>
                    Slogan <span className=""></span>
                  </label>
                  <input
                    value={deletePlanData.slogan}
                  />
                </div>
                <div className="input-wrapper">
                  <label>
                    Tag <span className="required">*</span>
                  </label>
                  <input
                    value={deletePlanData.planTag}
                  />
                </div>

                <div className="flexed">
                  {!deletePlanData?.isFreePlan && (
                    <>
                      <div className="input-wrapper">
                        <label>
                          Price <span className="required">*</span>
                        </label>
                        <input
                          value={deletePlanData.price}
                        />
                      </div>
                      <div className="input-wrapper">
                        <label>
                          Discount <span className="required">*</span>
                        </label>
                        <input
                          value={deletePlanData.discount}
                        />
                      </div>
                    </>
                  )}
                  <div className="input-wrapper">
                    <label>
                      Duration <span className="required">*</span>
                    </label>

                    <input
                      style={{ width: "200px" }}
                      value={deletePlanData.duration}
                    />
                  </div>
                </div>
              </div>
              <div className="form-section">
                <h4>Features</h4>

                <div className="flexed">
                  <div className="input-wrapper">
                    <label>
                      Curated Profiles <span className="required">*</span>
                    </label>
                    <input 
                    value={deletePlanData?.features?.curatedProfiles}
                     />
                  </div>

                  <div className="input-wrapper">
                    <label>
                      Monthly Curated Profiles{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      value={deletePlanData?.features?.monthlyCuratedProfiles}
                    />
                  </div>

                  <div className="input-wrapper">
                    <label>
                      Subscription Pause Days{" "}
                      <span className="required">*</span>
                    </label>
                    <input
                      value={deletePlanData?.features?.subscriptionPauseDays}
                    />
                  </div>
                </div>
              </div>

              <div className="form-section">
                <h4>Benifits</h4>

                {[1, 2, 3, 4, 5, 6].map((index) => (
                  <div className="input-wrapper" key={index}>
                    <label>Benifit {index}</label>
                    <input
                      value={deletePlanData?.features?.benifits[index - 1]}
                      
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="footer">
              <button 
              onClick={() => {deleteHandler(deletePlanData._id)}}
               type="button" className="primary">
                {selecteddeleteid ? `Delete` : `Delete`}
              </button>
            </div>
          </div>
      </div>
    </div>
        {selectedid && (
          <SubscriptionAdd
            openHandler={openHandler}
            selectedid={selectedid}
            fetchSubscriptions={fetchSubscriptions}
          />
        )}
        <SubscriptionTbl data={data} openHandler={openHandler} openDeleteHandler={openDeleteHandler} />
      </div>
    </div>
  );
}

export default Subscription;
