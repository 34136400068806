import { Link } from "react-router-dom";
import { MdRoomPreferences } from "react-icons/md";
import { TbMilitaryRank } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import MatchMaking from "../../pages/MatchMaking/MatchMaking";
import MatchSyncBrackets from "../../pages/MatchSync Brackets/MatchSyncBrackets";
import Users from "../../pages/User/Users";
import Referals from "../../pages/Referals/Referals";
import Login from "../../pages/Login/login";
import Subscription from "../../pages/subscription/subscription";
import Scorecards from "../../pages/Scorecards/Scorecards";
import Admins from "../../pages/Admins/Admins";
import DegradingPercentage from "../../pages/DegradingPercentage/DegradingPercentage";
import Addons from "../../pages/Addons/Addons";
import Notifications from "../../pages/Notifications/Notifications";

export default [
  {
    path: "/login",
    component: <Login />,
    isAuthenticated: false,
  },
  // {
  //   link: <Link to="/preferences">Preferences</Link>,
  //   icon: <MdRoomPreferences size={24} />,
  //   path: "/preferences",
  //   component: <Preferences />,
  //   isAuthenticated: true,
  // },
  {
    link: <Link to="/users?page=1">Users</Link>,
    icon: <FaUsers size={24} />,
    path: "/users",
    component: <Users />,
    isAuthenticated: true,
    isForRM: true,
  },
  {
    link: <Link to="/match-making">Match Making</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/match-making",
    component: <MatchMaking />,
    isAuthenticated: true,
    isForRM: true,
  },
  {
    link: <Link to="/referals?page=1">Referals</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/referals",
    component: <Referals />,
    isAuthenticated: true,
    isForRM: true,
  },
  {
    link: <Link to="/subscription">Subscription</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/subscription",
    component: <Subscription />,
    isAuthenticated: true,
  },
  {
    link: <Link to="/addons">Addons</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/addons",
    component: <Addons />,
    isAuthenticated: true,
  },
  {
    link: <Link to="/admins">Admins</Link>,
    icon: <FaUsers size={24} />,
    path: "/admins",
    component: <Admins />,
    isAuthenticated: true,
  },
  {
    link: <Link to="/scorecards">Scorecards</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/scorecards",
    component: <Scorecards />,
    isAuthenticated: true,
  },
  // {
  //   path: "/matehes/:id",
  //   component: <SeeMatches />,
  //   isAuthenticated: true,
  // },
  {
    link: <Link to="/matchsync-brackets">Match Sync Brackets</Link>,
    icon: <TbMilitaryRank size={24} />,
    path: "/matchsync-brackets",
    component: <MatchSyncBrackets />,
    isAuthenticated: true,
  },
  {
    link: <Link to="/degrading-percentage">Degrading Percentage</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/degrading-percentage",
    component: <DegradingPercentage />,
    isAuthenticated: true,
  },
  {
    link: <Link to="/notifications">Notifications</Link>,
    icon: <MdRoomPreferences size={24} />,
    path: "/notifications",
    component: <Notifications />,
    isAuthenticated: true,
    isForRM: true,
  },
];
