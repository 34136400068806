import React, { useEffect, useState } from "react";
import "./login.css";
import axios from "../../utils/axios";
import { Link, useNavigate } from "react-router-dom";
import authentication from "../../utils/authentication";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const LoginForm = () => {
  const navigate = useNavigate();

  const initialState = {
    email: "",
    password: "",
  };

  useEffect(() => {
    authentication.checkLogin();
  }, []);

  const [formData, setFormData] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);

  const changeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await axios("admins/login", "post", formData);
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("user", JSON.stringify(response.data.user));
      setFormData(initialState);
      window.location.href = "/users";
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <div className="login-container">
      <form className="login-wrapper">
        <div className="header">
          <div className="logo">
            <img src="/images/logo.svg" />
          </div>
        </div>
        <div className="content">
          <div className="input-wrapper">
            <label>Email</label>
            <input
              name="email"
              onChange={changeHandler}
              value={formData.email}
            />
          </div>
          <div className="input-wrapper">
            <label>Password</label>
            <input
              name="password"
              onChange={changeHandler}
              value={formData.password}
              type={showPassword ? "text" : "password"}
            
            />
            {formData.password && (
                <span
                  className="password-hide-show-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              )}
          </div>
        </div>
        <div className="footer">
          <button onClick={submitHandler} type="button" className="primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
