import React, { useCallback, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateUser } from "../../apis/users";
import axios from "../../utils/axios";
import authentication from "../../utils/authentication";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Add = ({ fetch, data, open, openHandler }) => {
  const [state, setState] = useState({
    "About Me": [],
    "My Journey": [],
    "My Lifestyle": [],
    "My Family": [],
    "My Future Outlook": [],
    "More About Me": [],
  });
  const [user, setUser] = useState(null);

  const updateStateHandler = useCallback(() => {
    if (data?._id) {
      setUser({ ...data });
      let temp = {
        "About Me": [],
        "My Journey": [],
        "My Lifestyle": [],
        "My Family": [],
        "My Future Outlook": [],
        "More About Me": [],
      };

      if (
        data?.dateOfBirth?.year &&
        data?.dateOfBirth?.month &&
        data?.dateOfBirth?.date
      ) {
        temp["About Me"].push({
          title: "Age",
          value: ageCalculator(
            `${data.dateOfBirth.year}-${data.dateOfBirth.month}-${data.dateOfBirth.date} 00:00:00`
          ),
        });
        temp["About Me"].push({
          title: "Date Of Birth",
          value: `${data.dateOfBirth.date} ${
            months[data.dateOfBirth.month - 1]
          } ${data.dateOfBirth.year}`,
        });
      }
      if (data?.height) {
        temp["About Me"].push({
          title: "Height",
          value: data.height,
        });
      }
      if (data?.workDetails?.annualEarning) {
        temp["About Me"].push({
          title: "Annual Income",
          value: `${data?.workDetails?.annualEarning}`,
        });
      }
      if (data?.maritalStatus) {
        temp["About Me"].push({
          title: "Marital Status",
          value: data.maritalStatus,
        });
      }
      if (data?.currentResidingCity) {
        temp["About Me"].push({
          title: "Current Residing City",
          value: data.currentResidingCity,
        });
      }
      if (data?.nationality) {
        temp["About Me"].push({
          title: "Nationality",
          value: data.nationality,
        });
      }
      if (data?.motherTongue) {
        temp["About Me"].push({
          title: "Mother Tongue",
          value: data.motherTongue,
        });
      }
      if (data?.religion) {
        temp["About Me"].push({
          title: "Religion",
          value: data.religion,
        });
      }
      if (data?.haveKids) {
        temp["About Me"].push({
          title: "Has kids?",
          value: data.haveKids,
        });
      }
      if (data?.livingArrangement) {
        temp["About Me"].push({
          title: "Living Arrangement",
          value: data.livingArrangement,
        });
      }
      if (data?.secondarySchool?.name) {
        let str = data?.secondarySchool?.name;
        if (
          data?.secondarySchool?.city &&
          data?.secondarySchool?.yearOfGraduation
        ) {
          str += ` (${data?.secondarySchool?.city} - ${data?.secondarySchool?.yearOfGraduation})`;
        } else if (data?.secondarySchool?.city) {
          str += ` (${data?.secondarySchool?.city})`;
        } else if (data?.secondarySchool?.yearOfGraduation) {
          str += ` (${data?.secondarySchool?.yearOfGraduation})`;
        }
        temp["My Journey"].push({
          title: "Secondary School",
          value: str,
        });
      }
      if (data?.graduationDegree?.course) {
        let str = data?.graduationDegree?.course;
        let infoArray = [
          data?.graduationDegree?.institution,
          data?.graduationDegree?.city,
          data?.graduationDegree?.yearOfCompletion,
        ]?.filter((el) => el);

        if (infoArray?.length > 0) {
          str += ` (${infoArray.join(" - ")})`;
        }

        temp["My Journey"].push({
          title: "Graduation Degree",
          value: str,
        });
      }
      if (data?.postGraduationDegree?.course) {
        let str = data?.postGraduationDegree?.course;
        let infoArray = [
          data?.postGraduationDegree?.institution,
          data?.postGraduationDegree?.city,
          data?.postGraduationDegree?.yearOfCompletion,
        ]?.filter((el) => el);

        if (infoArray?.length > 0) {
          str += ` (${infoArray.join(" - ")})`;
        }
        temp["My Journey"].push({
          title: "Post-Graduation Degree",
          value: str,
        });
      }
      if (data?.higherDegrees?.course) {
        let str = data?.higherDegrees?.course;
        let infoArray = [
          data?.higherDegrees?.institution,
          data?.higherDegrees?.city,
          data?.higherDegrees?.yearOfCompletion,
        ]?.filter((el) => el);

        if (infoArray?.length > 0) {
          str += ` (${infoArray.join(" - ")})`;
        }
        temp["My Journey"].push({
          title: "Higher Degree",
          value: str,
        });
      }

      temp["My Journey"].push({
        title: "Work Details",
        value: data?.workDetails?.designation
          ? `${data?.workDetails?.description} (${
              data?.workDetails?.designation +
              (data?.workDetails?.company
                ? ` - ${data?.workDetails?.company}`
                : "")
            })`
          : `${data?.workDetails?.description}`,
      });
      if (data?.eatingHabits) {
        temp["My Lifestyle"].push({
          title: "Food Habits",
          value: data.eatingHabits,
        });
      }
      if (data?.fitnessOutlook) {
        temp["My Lifestyle"].push({
          title: "Fitness Outlook",
          value: data.fitnessOutlook,
        });
      }
      if (data?.personality) {
        temp["My Lifestyle"].push({
          title: "Personality",
          value: data.personality,
        });
      }
      if (data?.idealWeekend) {
        temp["My Lifestyle"].push({
          title: "Ideal Holiday",
          value: data.idealWeekend,
        });
      }
      if (data?.smoke) {
        temp["My Lifestyle"].push({
          title: "Smokes",
          value: data.smoke,
        });
      }
      if (data?.drinkAlcohol) {
        temp["My Lifestyle"].push({
          title: "Drink Alcohol",
          value: data.drinkAlcohol,
        });
      }
      if (data?.familyDescription) {
        temp["My Family"].push({
          title: "Family Type",
          value: data.familyDescription,
        });
      }
      if (data?.fatherProfession) {
        temp["My Family"].push({
          title: "Father Profession",
          value: data.fatherProfession,
        });
      }
      if (data?.fatherIndustry) {
        temp["My Family"].push({
          title: "Father Industry",
          value: data.fatherIndustry,
        });
      }
      if (data?.motherProfession) {
        temp["My Family"].push({
          title: "Mother Profession",
          value: data.motherProfession,
        });
      }
      if (data?.motherIndustry) {
        temp["My Family"].push({
          title: "Mother Industry",
          value: data.motherIndustry,
        });
      }
      if (data?.siblings?.length > 0) {
        temp["My Family"].push({
          title: "Siblings",
          value: data.siblings.join(", "),
        });
      }
      if (data?.annualFamilyIncome) {
        temp["My Family"].push({
          title: "Annual Family Income",
          value: data.annualFamilyIncome,
        });
      }
      if (data?.wantKids) {
        temp["My Future Outlook"].push({
          title: "Preference for kids",
          value: data.wantKids,
        });
      }
      if (data?.livingArrangementPreference) {
        temp["My Future Outlook"].push({
          title: "Preference for Living Arrangement",
          value: data.livingArrangementPreference,
        });
      }

      if (data?.relocationCities?.length > 0) {
        temp["My Future Outlook"].push({
          title: "Open to relocate",
          value: `Open to relocate to - ${data.relocationCities
            ?.map((el) => el.name)
            ?.join(", ")}`,
        });
      } else {
        temp["My Future Outlook"].push({
          title: "Open to relocate",
          value: `Open to relocate to - ${data.currentResidingCity}`,
        });
      }
      if (data?.roleModel) {
        temp["More About Me"].push({
          title: "Who is your role model?",
          value: data.roleModel,
        });
      }
      if (data?.describe) {
        temp["More About Me"].push({
          title: "Describe yourself in 3 words",
          value: data.describe,
        });
      }
      if (data?.proudOf) {
        temp["More About Me"].push({
          title: "What are you most proud of?",
          value: data.proudOf,
        });
      }

      if (data?.kundliMatching) {
        temp["More About Me"].push({
          title: "Does your family believe in kundli matching?",
          value: data.kundliMatching,
        });
      }

      if (data?.timelineExpectation) {
        temp["More About Me"].push({
          title: "What is your timeline expectation of this journey?",
          value: data.timelineExpectation,
        });
      }
      if (data?.comments) {
        temp["More About Me"].push({
          title: "Other comments",
          value: data.comments,
        });
      }

      setState({ ...temp });
    }
  }, [data]);

  useEffect(() => {
    updateStateHandler();
  }, [data]);

  const fileChangeHandler = useCallback(
    async (e) => {
      if (e?.target?.files[0]) {
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };
        const form = new FormData();
        form.append("file", e?.target?.files[0]);

        try {
          const res = await axios("users/video", "post", form, headers);
          await updateUser(user?._id, { video: res.data.url });
          fetch();
          toast.success("User profile video successfully");
        } catch (error) {
          if (error?.response?.status === 401) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.removeAuthentication();
            }, 1000);
          } else if (error?.response?.status === 403) {
            toast.error(error.response.data.message);
            setTimeout(() => {
              authentication.reirectToUsers();
            }, 1000);
          } else {
            toast.error("Something went wrong");
          }
        }
      }
    },
    [user]
  );

  const imageChangeHandler = useCallback(
    async (e) => {
      if (user?.images?.length > 3) {
        toast.error(
          "Only four images can be uploaded! Please remove one and retry"
        );
      } else {
        if (e?.target?.files[0]) {
          const token = localStorage.getItem("token");
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          };
          const form = new FormData();
          form.append("file", e?.target?.files[0]);

          try {
            const res = await axios("users/video", "post", form, headers);
            await updateUser(user?._id, {
              images: [...user?.images, res.data.url],
            });
            fetch();
            toast.success("User profile images successfully");
          } catch (error) {
            if (error?.response?.status === 401) {
              toast.error(error.response.data.message);
              setTimeout(() => {
                authentication.removeAuthentication();
              }, 1000);
            } else if (error?.response?.status === 403) {
              toast.error(error.response.data.message);
              setTimeout(() => {
                authentication.reirectToUsers();
              }, 1000);
            } else {
              toast.error("Something went wrong");
            }
          }
        }
      }
    },
    [user]
  );
  const removeImageHandler = useCallback(
    async (index) => {
      try {
        let temp = user?.images?.filter((el, i) => index !== i) || [];

        await updateUser(user?._id, { images: temp || [] });
        fetch();
        toast.success("User profile images successfully");
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
    [user]
  );

  const ageCalculator = (date) => {
    var dob = new Date(date);
    var month_diff = Date.now() - dob.getTime();

    var age_dt = new Date(month_diff);

    var year = age_dt.getUTCFullYear();

    var age = Math.abs(year - 1970);
    return age;
  };

  const removeVideoHandler = useCallback(
    async (index) => {
      try {
        await updateUser(user?._id, { video: "" });
        fetch();
        toast.success("Video removed successfully");
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.removeAuthentication();
          }, 1000);
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
          setTimeout(() => {
            authentication.reirectToUsers();
          }, 1000);
        } else {
          toast.error("Something went wrong");
        }
      }
    },
    [user]
  );

  return (
    <div className="modal-container">
      {open && (
        <div className="modal active">
          <div className="wrapper full">
            <div className="header">
              <h2>{user?.name}</h2>
              <button type="button" className="danger" onClick={openHandler}>
                Close
              </button>
            </div>
            <div className="content">
              <div className="form-section">
                <h4>Profile Video</h4>
                <div className="input-wrapper-image">
                  <div className="video">
                    {user?.video && (
                      <>
                        <button onClick={() => removeVideoHandler()}>X</button>
                        <video controls src={user?.video} />
                      </>
                    )}
                  </div>
                  <label htmlFor={`${user?._id}-video`}>
                    Upload Video (1080 * 1350 pixels)
                  </label>
                  <input
                    style={{ display: "none" }}
                    className="uploadAsset"
                    id={`${user?._id}-video`}
                    type="file"
                    accept="video/*"
                    onChange={(e) => fileChangeHandler(e)}
                  />
                </div>
              </div>

              <div className="form-section">
                <h4>Profile picture</h4>
                <div className="flexed-images">
                  {user?.images?.map((el, index) => (
                    <div className="image-wrapper">
                      <button onClick={() => removeImageHandler(index)}>
                        X
                      </button>
                      <img src={el} />
                    </div>
                  ))}
                  {user?.images?.length < 4 && (
                    <div className="input-wrapper-image">
                      <label htmlFor={`${user?._id}-image`}>
                        Upload Image (Aspect ratio 4:5)
                      </label>
                      <input
                        style={{ display: "none" }}
                        className="uploadAsset"
                        id={`${user?._id}-image`}
                        type="file"
                        accept="image/*"
                        onChange={(e) => imageChangeHandler(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
              {Object.keys(state)?.length > 0 && (
                <>
                  {Object.keys(state)
                    .filter((item) => state[item]?.length > 0)
                    ?.map((el) => (
                      <div className="form-section">
                        <h4>{el}</h4>
                        <div className="flexed">
                          {state[el].map((el1, index) => (
                            <div
                              className="input-wrapper"
                              key={`${el1.title}-${index}`}
                            >
                              <label>{el1.title}</label>
                              <span>{el1.value}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </>
              )}
              {user?.preferences?.length > 0 && (
                <div className="form-section">
                  <h4>Preferences</h4>
                  <ul className="preferences">
                    {user?.preferences?.map((item) => (
                      <li>
                        <h5>{item.name}</h5>
                        <div className="options">
                          {item.options?.map((el) => (
                            <div className="input-wrapper checkboxes">
                              <input type="checkbox" checked={el.isSelecetd} />
                              <label>{el.name}</label>
                            </div>
                          ))}
                        </div>
                        <div className="scorecards">
                          {item.scorecards?.map((el) => (
                            <button className={el.isSelecetd ? "selected" : ""}>
                              {el.name}
                            </button>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Add;
