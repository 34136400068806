import React, { useState, useCallback, useEffect } from "react";
import { getReferals, getRMAdmins, updateReferal } from "../../apis/users";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import authentication from "../../utils/authentication";

const Referals = () => {
  const [data, setData] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const page = useLocation().search.split("=")[1];

  const fetchUsers = useCallback(async () => {
    try {
      const apiResponse = await getReferals(page);
      if (apiResponse?.data?.data?.data?.length > 0) {
        setData([...apiResponse?.data?.data?.data]);
        setPageInfo({ ...apiResponse?.data?.data?.pageInfo });
      } else {
        setData([]);
        setPageInfo(null);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  const fetchRMAdmins = useCallback(async () => {
    try {
      const apiResponse = await getRMAdmins();
      if (apiResponse?.data?.data?.length > 0) {
        setAdmins([...apiResponse?.data?.data]);
      } else {
        setAdmins([]);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchRMAdmins();
  }, []);

  const updateReferals = useCallback(async (id, assignedRM) => {
    try {
      await updateReferal(id, assignedRM);
      fetchUsers();
      toast.success("Relationship Manager assigned successfully");
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.removeAuthentication();
        }, 1000);
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
        setTimeout(() => {
          authentication.reirectToUsers();
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    }
  }, []);

  return (
    <div className="section">
      <div className="wrapper">
        <div className="table-wrapper">
          <table>
            <thead>
              <th>Date</th>
              <th>Fullname</th>
              <th>Phone Number</th>
              <th>Relationship</th>
              <th>Refered By</th>
              <th>Relationship Manager</th>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item._id}>
                  <td>{item.createdAt}</td>
                  <td>{item.fullname}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.relationship}</td>
                  <td>{item.user}</td>
                  <td>
                    <div className="input-wrapper">
                      <select
                        value={item?.admin?._id || ""}
                        onChange={(event) =>
                          updateReferals(item._id, event.target.value)
                        }
                      >
                        <option
                          value=""
                          disabled
                          style={{ display: "none" }}
                        ></option>
                        {admins?.map((admin) => (
                          <option key={admin._id} value={admin._id}>
                            {admin.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="table-footer">
            {!pageInfo?.prevPage ? (
              <button className="secondary disabled">Prev</button>
            ) : (
              <Link to={`/users?page=${pageInfo.prevPage}`}>
                <button className="secondary">Prev</button>
              </Link>
            )}
            {!pageInfo?.nextPage ? (
              <button className="secondary disabled">Next</button>
            ) : (
              <Link to={`/users?page=${pageInfo.nextPage}`}>
                <button className="secondary">Next</button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referals;
